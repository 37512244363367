import { Alert, Empty, Space, Tabs, Modal, Drawer, message, Spin } from "antd";
import { useEffect, useRef, useState } from "react";

import { useHistory, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { BreadCrumbs } from "../../../atoms/BreadCrumb/BreadCrumb";
import {
  Button,
  MoreButton,
  SecondaryButton,
} from "../../../atoms/Button/Button";
import { SingleSelect } from "../../../atoms/Select/Select";
import { PlusIcon, RentalRevenue } from "../../../icons";
import { DetailHeader } from "../../../molecules/list-header/DetailHeader";
import {
  getCurrentStatusFromKey,
  VehicleStatuses,
} from "../../../options/VehicleStatus";
import Lottie from "react-lottie-player";
import { useVehicles } from "../context";
import { BasicInformations } from "./sections/BasicInformations";
import { FinancialInformations } from "./sections/FinancialInformations";
import user from "../../../icons/designIcons/dummyVehicle.svg";
import UploadCam from "../../../icons/designIcons/UploadCam.svg";
import { ActiveEditPen, EditPenIcon } from "../../../icons/edit-pen";
import { FinancialDetail } from "./sections/sub-sections/FinancialDetails";
import { VehicleOverview } from "./sections/sub-sections/VehicleOverview";
import { ServiceRemainder } from "../../remainders/serviceRemainders/ServiceRemainder";
import OverDue from "../../../LottiJson/OverDue.json";
import {
  calculateDueDate,
  getAging,
  getVehicleTypeLabel,
  UploadData,
} from "../../../options/VehicleLoan";
import {
  AgingVehicle,
  HealthyVehicle,
  OldVehicle,
} from "../../../icons/VehicleAging";
import {
  backgroundColorCode,
  colorCode,
  GetuploadUrl,
  returnPriceValue,
  UploadFiles,
} from "../../../utils";
import { PermissionsGate } from "../../../atoms/PermissionComponent/PermissionGate";
import { DueSoon, OKDue } from "../../../icons/vehicleDue";
import { ServiceHistory } from "../../servicesHistory/all/AllHistoryListing";
import { useCommonContext } from "../../../hooks/commonStateProvider";
const { TabPane } = Tabs;
const { confirm } = Modal;
let agingValue;
export const VehicleDetails: React.FC<{}> = (props) => {
  const [currentTab, setCurrentTab] = useState("info");
  const [currentStatus, setCurrentStatus]: any = useState({});
  const [VehicleAge, setVehicleAge]: any = useState("");
  const [dueStatus, setDueStatus]: any = useState("");
  const { hash } = useLocation();
  const { id }: any = useParams();
  const [uploadLoading, setuploadLoading] = useState(false);
  const [addService, setAddservice]: any = useState({});
  const history = useHistory();
  const {
    actions: { getOneVehicle, updateOne, getVehicleHistory },
    state: { vehicle },
  } = useVehicles();

  useEffect(() => {
    getOneVehicle(id);
    getAllStatus();
  }, []);

  useEffect(() => {
    setVehicleAge(
      getAging(vehicle.financial?.purchaseDate, vehicle.financial?.saleMillage)
    );

    setCurrentStatus(getCurrentStatusFromKey(vehicle?.status));
    setDueStatus(calculateDueDate(vehicle?.registrationExpirationDate));
  }, [vehicle]);
  useEffect(() => {
    setCurrentTab(hash || "#info");
  }, [hash]);
  const {
    state: { status },
    actions: { getAllStatus },
  } = useCommonContext();

  const UploadProfile = async (e: any) => {
    let file = e.target.files[0];
    if (file) {
      if (file.type === "image/png" || file.type === "image/jpeg") {
        setuploadLoading(true);
        let uploadData = UploadData(`image/${file.name}`, id, file.type);
        uploadData.key = `vehicles/${id}/${file.name}`;
        let res = await GetuploadUrl("/assets/upload/write", uploadData);

        if (res) {
          let url = res.data.signedURL;
          let uploadedData = await UploadFiles(url, file, file.type);

          if (uploadedData.status === 200) {
            let data = {
              primaryPhoto: `vehicles/${id}/${file.name}`,
            };
            updateOne(id, data, (res) => {
              if (res) {
                setuploadLoading(false);
                getOneVehicle(id);
              }
            });
          }
        }
      } else {
        message.error("Please upload only valid photos");
      }
    }
  };

  return (
    <>
      {vehicle && (
        <>
          <div className=" bg-white px-6">
            <div className="flex justify-between items-center py-[24px]">
              <BreadCrumbs showTitle={vehicle.vehicleName} />
              <div>
                <div className="flex items-center flex-row-reverse gap-4">
                  {" "}
                  <PermissionsGate module="vehicle" action="edit">
                    {hash === "#service-history" ? (
                      <>
                        <Button
                          onClick={() => {
                            setAddservice({ visible: true, isNew: true });
                          }}
                          className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
                        >
                          <span>
                            <PlusIcon />
                          </span>
                          Add
                        </Button>
                      </>
                    ) : (
                      <>
                        <Link to={`/vehicles/edit/${id}${currentTab}`}>
                          {" "}
                          <button className="text-[#FB6DB2] items-center flex text-[13px] font-semibold border border-[#FB6DB2] px-[16px] rounded-[4px] py-[10px]">
                            <div className="mr-2">
                              {" "}
                              <ActiveEditPen />
                            </div>{" "}
                            Edit
                          </button>
                        </Link>
                      </>
                    )}
                  </PermissionsGate>
                  <SingleSelect
                    label=""
                    className="w-[190px] nav_single_select "
                    inputClassName="h-full"
                    value={currentStatus}
                    options={status}
                    onChange={(newValue: any) => {
                      setCurrentStatus(newValue);
                      confirm({
                        title: "Warning",
                        content: "Are you sure want to update the status?",
                        onOk() {
                          updateOne(id, { status: newValue.value }, () => {
                            getOneVehicle(id);
                            window.location.reload();
                          });
                        },
                        onCancel() {
                          setCurrentStatus(
                            getCurrentStatusFromKey(vehicle.status)
                          );
                        },
                      });
                    }}
                  />
                  <MoreButton />
                </div>
              </div>
            </div>
          </div>

          <div className="flex px-[24px] mb-[37px]">
            <div className="rounded-[50%] w-[98px] h-[98px] p-[7px] bg-[#F4F4F4] mr-[18px] flex items-center justify-center relative">
              {uploadLoading ? (
                <Spin />
              ) : (
                <img
                  className="rounded-[50%] w-full h-full object-cover"
                  src={
                    vehicle?.primaryPhoto
                      ? `${process.env.REACT_APP_S3_API_ENDPOINT}/${
                          vehicle?.primaryPhoto
                        }?${Date.now()}`
                      : user
                  }
                />
              )}

              <div className="w-[30px] cursor-pointer h-[30px] absolute bg-[#FB6DB2] flex items-center justify-center rounded-[50%] right-0 bottom-0">
                <div className="relative flex justify-center items-center w-full h-full cursor-pointer">
                  <input
                    type="file"
                    className="cursor-pointer w-full opacity-0 h-full absolute"
                    onChange={(e) => UploadProfile(e)}
                    accept="image/png,image/jpeg"
                  />
                  <img src={UploadCam} />
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-center mb-[16px]">
                {" "}
                <h3 className="font-medium text-[18px] ">
                  {vehicle?.vehicleName}
                </h3>
                {dueStatus > 30 && (
                  <div className="ml-4 flex items-center">
                    <OKDue />
                    <p className="ml-2 text-[#3BA936] text-[13px] font-semibold">
                      Valid Registration in place
                    </p>
                  </div>
                )}
                {dueStatus > 3 && dueStatus <= 30 && (
                  <div>
                    <div className="ml-4 flex items-center">
                      <DueSoon />
                      <p className="ml-2 text-[#F1A309] text-[13px] font-semibold">
                        Registration Due Soon
                      </p>
                    </div>
                  </div>
                )}
                {dueStatus <= 3 && (
                  <div>
                    <div className="ml-4 flex items-center">
                      <Lottie
                        loop
                        animationData={OverDue}
                        play
                        style={{ width: 35, height: 35 }}
                      />
                      <p className=" text-[#E93030] text-[13px] font-semibold">
                        Registration Over due
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex mb-[16px]">
                <p className="capitalize pr-[12px] font-medium text-[13px] text-[#757575] mr-[12px] border-r-2 border-[#f5f5f5]">
                  {vehicle?.vehicleType?.name}
                </p>{" "}
                <p className=" pr-[12px] font-medium text-[13px] text-[#757575] mr-[12px] border-r-2 border-[#f5f5f5]">
                  {typeof vehicle?.make === "object"
                    ? vehicle?.make?.name
                    : vehicle?.make}{" "}
                  {typeof vehicle?.model === "object"
                    ? vehicle?.model?.name
                    : vehicle?.model}
                </p>{" "}
                <p className=" pr-[12px] font-medium text-[13px] text-[#757575] mr-[12px] border-r-2 border-[#f5f5f5]">
                  {vehicle?.licensePlate}
                </p>
                <p className=" pr-[12px] font-medium text-[13px] text-[#757575] mr-[12px] border-r-2 border-[#f5f5f5]">
                  {vehicle?.vin}
                </p>
                {VehicleAge && (
                  <div className="">
                    {VehicleAge === "old" ? (
                      <div className="flex items-center">
                        {" "}
                        <OldVehicle />{" "}
                        <p className="ml-2 text-[13px] text-[#757575] font-medium">
                          Old
                        </p>
                      </div>
                    ) : VehicleAge === "aging" ? (
                      <div className="flex items-center">
                        {" "}
                        <AgingVehicle />
                        <p className="ml-2 text-[13px] text-[#757575] font-medium">
                          Aging
                        </p>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        {" "}
                        <HealthyVehicle />
                        <p className="ml-2 text-[13px] text-[#757575] font-medium">
                          Healthy
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="flex">
                <div
                  className={`flex items-center  text-[13px] font-medium mr-[12px] capitalize rounded-[35px] px-[14px] py-[6px] ${backgroundColorCode(
                    vehicle.status
                  )}`}
                >
                  <p className={colorCode(vehicle.status)}>
                    {getCurrentStatusFromKey(vehicle?.status)?.label}
                  </p>
                </div>
                {vehicle?.odometer ? (
                  <div className=" mr-[12px] border-dashed py-[6px] px-[15px] border-[#C8C4C4] border text-[13px] font-medium text-[#757575] rounded-[30px]">
                    {vehicle?.odometer} mi
                  </div>
                ) : null}
                {vehicle?.group ? (
                  <div className="cursor-pointer flex items-center mr-[12px] border-dashed py-[6px] px-[15px] border-[#C8C4C4] border text-[13px] font-medium text-[#757575] rounded-[30px]">
                    <p className="">{vehicle?.group.name}</p>
                  </div>
                ) : null}
                {vehicle?.financial?.rentalRevenue && (
                  <div className="py-[6px] flex items-center  mr-3  px-[15px] bg-[#F1FAFF] rounded-[30px] text-[#398CC0] text-[13px] font-medium">
                    <RentalRevenue />
                    <span className="pl-2">
                      {returnPriceValue(vehicle.financial.rentalRevenue)}
                    </span>
                  </div>
                )}

                <div className=" mr-[12px] flex items-center  text-[13px] font-medium text-[#757575]">
                  <div
                    className={`mr-[8px] rounded-[50%] w-[12px] h-[12px] ${
                      vehicle.fuelType === "gasoline"
                        ? "bg-[#F25138]"
                        : vehicle.fuelType === "diesel"
                        ? "bg-[#00AEEF]"
                        : vehicle.fuelType === "flex-fuel"
                        ? "bg-[#98fb98]"
                        : "bg-[#3BA936]"
                    }  `}
                  ></div>
                  <p className="capitalize">
                    {getVehicleTypeLabel(vehicle?.fuelType)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Tabs
            activeKey={currentTab}
            className="vehicle_detial_tab"
            onChange={(activeKey) => {
              setCurrentTab(activeKey);
              history.push({
                hash: activeKey,
              });
            }}
          >
            <TabPane
              key="#info"
              tab={
                <span className="tab-item flex justify-center items-center text-[14px]">
                  Overview
                </span>
              }
            >
              <VehicleOverview initialData={vehicle} />
              {/* <BasicInformations
            isNew={false}
            existingData={vehicle}
            id={vehicleId}
          /> */}
            </TabPane>
            <TabPane
              tab={
                <span className="tab-item flex justify-center items-center">
                  Financial
                </span>
              }
              key="#financial"
            >
              <FinancialDetail existingData={vehicle} />
              {/* <FinancialInformations
            isNew={false}
            existingData={vehicle}
            id={vehicleId}
          /> */}
            </TabPane>
            <TabPane
              tab={
                <span className="tab-item flex justify-center items-center">
                  Service History
                </span>
              }
              key="#service-history"
            >
              {hash === "#service-history" && (
                <ServiceHistory
                  addServiceByVehicle={addService}
                  setAddServiceByVehicle={setAddservice}
                  isVehiclePage={true}
                />
              )}
            </TabPane>
            <TabPane
              tab={
                <span className="tab-item flex justify-center items-center">
                  Service Reminders
                </span>
              }
              key="#service-reminders"
            >
              {/* <ServiceRemainder /> */}

              <Empty
                style={{ height: 350 }}
                className="flex justify-center flex-col items-center"
                description="Yet to be developed"
              />
            </TabPane>
            <TabPane
              tab={
                <span className="tab-item flex justify-center items-center">
                  Renewal Reminders
                </span>
              }
              key="#renewal-reminders"
            >
              {/* <RenewalRemainder /> */}
              <Empty
                style={{ height: 350 }}
                className="flex justify-center flex-col items-center"
                description="Yet to be developed"
              />
            </TabPane>
            <TabPane
              tab={
                <span className="tab-item flex justify-center items-center">
                  Inspections
                </span>
              }
              key="#inspections"
            >
              <Empty
                style={{ height: 350 }}
                className="flex justify-center flex-col items-center"
                description="Yet to be developed"
              />
            </TabPane>
            <TabPane
              tab={
                <span className="tab-item flex justify-center  items-center">
                  Trip Activity
                </span>
              }
              key="#trip-activity"
            >
              <Empty
                style={{ height: 350 }}
                className="flex justify-center flex-col items-center"
                description="Yet to be developed"
              />
            </TabPane>
          </Tabs>
        </>
      )}
    </>
  );
};
