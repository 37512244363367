import { Form, Formik } from "formik";
import { FiCheck, FiEdit, FiXCircle } from "react-icons/fi";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useInspections } from "../InspectionContext";
import { Button } from "../../../atoms/Button/Button";
import { FormikText } from "../../../atoms/Text/Text";
import { FormikSinglSelect } from "../../../atoms/Select/Select";
import { useVehicles } from "../../vehicles/context";
import { useFormTemplates } from "../TemplateContext";
import { SaveIcon } from "../../../icons/SaveIcon";

interface VehicleInformationsProps {
  initialData?: any;
  id?: string;
  isNew?: boolean;
  onClose?: () => void;
}

export const CreateOneInspection: React.FC<VehicleInformationsProps> = ({
  initialData,
  id,
  isNew,
  onClose,
}: VehicleInformationsProps) => {
  const [canEdit, setEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    setInitialValues({
      description: "",
      template: "",
      vehicle: "",
      dueDate: "",
    });
  }, [initialData]);

  const {
    state: { minimalDocuments: mininalVehicles },
  } = useVehicles();

  const {
    state: { minimalDocuments: mininalForms },
  } = useFormTemplates();
  const {
    actions: { createOne, updateOne, getOne },
  } = useInspections();

  return (
    <div className="section mt-2">
      {/* Section Header */}
      <div className="flex justify-between items-center border-b border-gray-300 border-solid pb-2">
        <div>
          <h3 className="text-base font-semibold">Basic Informations</h3>
        </div>
        {!canEdit && !isNew && (
          <div className="flex gap-2">
            <Button
              className="border-2 border-solid px-7 py-2"
              onClick={() => setEdit(true)}
            >
              <span className="flex items-center">
                <FiEdit className="mr-1" />
                Edit
              </span>
            </Button>
          </div>
        )}
      </div>
      {/* Form Elements */}
      <div className="mt-5">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={Yup.object({
            description: Yup.string().required("Description of the Inspection is required"),
            template: Yup.mixed().required("Form Template is required"),
            vehicle: Yup.mixed().required("Vehicle is required"),
            dueDate: Yup.mixed().required("Due Date is required"),
          })}
          onSubmit={(values: any, actions) => {
            // Update One
            if (!isNew && id) {
              updateOne(id, values, () => {
                setEdit(false);
                getOne(id);
              });
            } else {
              // Create One
              createOne(
                {
                  ...values,
                  template: values.template.value,
                  vehicle: values.vehicle.value,
                },
                () => {
                  actions.resetForm(initialValues);
                  onClose && onClose();
                }
              );
            }
          }}
        >
          {({ values, setFieldValue, setErrors, resetForm }) => (
            <Form className="mt-10">
              <div className="flex justify-between mb-[30px]">
                <FormikText
                  label="Description"
                  name="description"
                  disabled={!canEdit && !isNew}
                />
                <FormikText
                  label="Due Date"
                  name="dueDate"
                  type={"date"}
                  disabled={!canEdit && !isNew}
                />
              </div>

              <div className="flex gap-16 mb-[30px]">
                <div className="min-w-[360px]"> <FormikSinglSelect
                  label="Form Template"
                  name="template"
                  options={mininalForms}
                  onChange={(newValue) => setFieldValue("template", newValue)}
                  isDisabled={!canEdit && !isNew}
                /></div>

                <div className="min-w-[360px]">
                  <FormikSinglSelect
                    label="Vehicle"
                    name="vehicle"
                    options={mininalVehicles}
                    onChange={(newValue) => setFieldValue("vehicle", newValue)}
                    isDisabled={!canEdit && !isNew}
                  />
                </div>
              </div>

              <div className="mt-10 flex flex-row-reverse gap-4">
               
              {(canEdit || isNew) && (
                <div className="flex justify-end gap-2">
                  
                  <Button
                  className="border py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
                  type="button"
                  onClick={() => {
                    setEdit(false);
                    setErrors({});
                    onClose && onClose();
                    resetForm(initialValues);
                  }}
                >
                  <span className="flex items-center text-[#FB6DB2]">
                    Cancel
                  </span>
                </Button>
                  <Button
                  type="submit"
                  className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
                >
                  <span>
                    <SaveIcon />
                  </span>
                  {!isNew ? "Save" : "Create"} Inspection
                </Button>
                  
                </div>
              )}

              </div>
              
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
