import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { BreadCrumbs } from "../../../atoms/BreadCrumb/BreadCrumb";
import { Button } from "../../../atoms/Button/Button";
import { SaveIcon } from "../../../icons/SaveIcon";
import * as Yup from "yup";
import { FormikText } from "../../../atoms/Text/Text";
import { FormikSinglSelect } from "../../../atoms/Select/Select";
import { VendorTypeOptions } from "../../vendors/VendorLoans";
import { FormikDatePicker } from "../../../atoms/DatePicker/DatePicker";
import uploadIcon from "../../../icons/designIcons/uploadIcon.svg";
import { useContacts } from "../context";
import { addIsUploadedKey, getAwsUploadUrl, setSelectedValue } from "../../../utils";
import { message } from "antd";
import { CancelUpload, PdfImage } from "../../../icons/UploadIcons";
import { Spinner } from "../../../atoms/Spinner/spinner";

export interface OneContactProps {}

export const OneContact: React.FC<OneContactProps> = () => {
  const formRef = useRef() as any;
  const [ZeebaGroupList, setZeebaGroup] = useState([]);
  const [attachment, setFile]: any = useState([]);
  const [spinner, setSpinner] = useState(false);
  const { id }: any = useParams();
  const handleSubmit = () => {
    formRef.current.handleSubmit();
  };
  const history = useHistory();
  const {
    state: { contact },
    actions: {
      updateOne,
      getAllContacts,
      createOne,
      getOneContact,
      getAllGroups,
    },
  } = useContacts();
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    group: "",
    middleName: "",
    workPhoneNumber: "",
    address: "",
    addressLineTwo: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
  });
  useEffect(() => {
    if (id) {
      getOneContact(id);
    }
  }, []);

  useEffect(() => {
    if (id) {
      setInitialValues({
        ...contact,
        group: setSelectedValue(contact?.group),
      });
      if (contact.attachments?.length > 0) {
        setFile(addIsUploadedKey(contact.attachments));
      }
    }
  }, [contact]);

  useEffect(() => {
    getAllGroups((res) => {
      let newAray: any = [];
      res.documents.map((item: any) => {
        let obj = {
          label: item.name,
          value: item.id,
        };
        newAray.push(obj);
      });
      setZeebaGroup(newAray);
    });
    if (id) {
      // getOneVehicle(id, () => {});
    } else {
    }
  }, []);

  const uploadFunction = (e: any) => {
    let files = e.target.files;
    let length = attachment.length;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let item = files[i];
        let incrementedIndex = i + 1; // incrementing an variable as loop runs before updating the state.

        /*
          condition to not upload more than five files.
        */

        if (incrementedIndex + length <= 5) {
          if (
            item.type !== "image/png" &&
            item.type !== "image/jpeg" &&
            item.type !== "image/jpg" &&
            item.type !== "application/pdf" &&
            item.type !== "text/csv" &&
            item.type !==
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            message.error("Please upload only jpeg,jpg or png images");
          } else if (item.size > 5 * 1000000) {
            message.error("Couldn't upload large files");
          } else {
            let data = {
              orginal_file: item,
              name: item.name,
              url: window.URL.createObjectURL(item),
              type: item.type,
            };
            setFile((individualFiles: any) => [...individualFiles, data]);
          }
        } else return message.error("You can upload upto 5 attachments");
      }
    }
  };
  const deleteUploaded = (i: any) => {
    attachment.filter((item: any, index: any) => {
      if (index === i) {
        attachment.splice(i, 1);
      }
      setFile([...attachment]);
    });
  };

  const uploadAttachments = async () => {
    if (attachment.length > 0) {
      let attachments: any = [];
      let uploadedArray = await getAwsUploadUrl(attachment, "contacts/");
      uploadedArray.map((item: any) => {
        let obj = {
          url: item.url,
          type: item.type,
          name: item.name,
        };
        attachments.push(obj);
      });
      return attachments;
    }
  };
  return (
    <>
      <div className="px-[24px]">
        <div className="flex justify-between items-center py-[24px]">
          <BreadCrumbs />
          <div>
            <div className="flex flex-row-reverse gap-4">
              {" "}
              <Button
                type="submit"
                onClick={() => handleSubmit()}
                className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
              >
                <span>
                  {spinner ? (
                    <Spinner CustomClassName={"spinner_secondary"} />
                  ) : (
                    <SaveIcon />
                  )}
                </span>
                Save Contact
              </Button>
              <Button
                className="border  py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
                type="button"
                onClick={() => history.goBack()}
              >
                <span className="flex items-center text-[#FB6DB2]">Cancel</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="card_default rounded-[5px] mb-10 p-[24px]">
          <h2 className="text-[18px] font-[600] mb-5">Basic Details</h2>
          <div className="">
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              enableReinitialize
              validationSchema={Yup.object({
                group: Yup.mixed().required("Group is required"),
                firstName: Yup.string().required("First Name is required"),
                lastName: Yup.string().required("Last Name is required"),
                email: Yup.string().required("Email is required"),
                phoneNumber: Yup.string()
                  .matches(
                    /^[0-9]{10}$/,
                    "Mobile number should contain valid 10 numbers"
                  )
                  .required("Mobile number is required"),
              })}
              onSubmit={async (values: any, actions) => {
                setSpinner(true);
                values = {
                  ...values,
                };
                if (attachment.length > 0) {
                  values["attachments"] = await uploadAttachments();
                }
                if (id) {
                  updateOne(
                    id,
                    {
                      ...values,
                      group: values.group.value,
                    },
                    (res) => {
                      history.goBack();
                      actions.resetForm();
                    }
                  );
                } else {
                  createOne({ ...values, group: values.group.value });
                }
              }}
            >
              {({ values, setFieldValue }) => (
                <Form className="" id="my-form">
                  <div className="flex justify-between mb-[30px]">
                    <FormikText
                      label="First Name "
                      must={true}
                      name="firstName"
                    />
                    <FormikText label="Middle Name" name="middleName" />
                    <FormikText label="Last Name" name="lastName" must={true} />
                  </div>
                  <div className="flex gap-16 mb-[30px]">
                    <FormikText label="Email" name="email" must={true} />
                    <div className="min-w-[360px]">
                      <FormikSinglSelect
                        must={true}
                        isClearable
                        options={ZeebaGroupList}
                        label="Group"
                        name="group"
                        inputClassName="adjust_select_height"
                        onChange={(newValue) =>
                          setFieldValue("group", newValue)
                        }
                      />
                    </div>
                  </div>
                  {/* <div className="mb-[34px]">
                    <div className="border border-dashed relative border-[#EBEBEB] rounded-[10px] bg-[#FCFCFC] w-[400px] p-[30px] flex ">
                      <input
                        type="file"
                        id="InputUpload"
                        className="w-full h-full opacity-0 absolute cursor-pointer "
                       
                        accept="image/png,image/jpeg"
                      />

                      <img className=" mr-3 " src={uploadIcon} />
                      <div>
                        <p className="text-[16px] font-medium mb-[12px]">
                          Click to Upload or Drag and Drop
                        </p>
                        <p className="text-[14px] text-[#757575]">
                          Maximum file size 4 MB
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <div>
                    {" "}
                    <h2 className="text-[18px] font-[600] mb-5">
                      Contact Information
                    </h2>
                  </div>
                  <div>
                    {" "}
                    <div className="flex justify-between mb-[30px]">
                      <FormikText
                        label="Mobile Phone Number"
                        name="phoneNumber"
                        must={true}
                      />
                      <FormikText
                        label="Work Phone Number"
                        name="workPhoneNumber"
                      />
                      <FormikText
                        label="Address"
                        inputClassName="w-full"
                        name="address"
                      />
                    </div>{" "}
                    <div className="flex justify-between mb-[30px]">
                      <div className="min-w-[360px] ">
                        <FormikText
                          label="Address Line 2"
                          inputClassName="w-full"
                          name="addressLineTwo"
                        />
                      </div>
                      <FormikText label="City" name="city" />
                      <FormikText label="State/Province/Region" name="state" />
                    </div>
                    <div className="flex gap-16 mb-[30px]">
                      <FormikText label="Zip/Postal Code" name="zipcode" />
                      <div className="min-w-[360px] ">
                        <FormikText label="Country" name="country" />
                        {/* <FormikSinglSelect
                          isClearable
                          options={countryList}
                          label="Country"
                          name="country"
                          value={values.country}
                          inputClassName="adjust_select_height"
                          onChange={(newValue) => {
                            setFieldValue("country", newValue);
                          }}
                        /> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="mt-8">
                    <h2 className="text-[18px] font-[600] mb-5">
                      Upload
                    </h2>{" "}
                    <div className="flex justify-between mb-[30px]">
                      <FormikText label="Job Title" name="vehicle" />
                      <div className="min-w-[360px]">
                        <FormikDatePicker
                          inputClassName="datepicket_styles"
                          label="Date of Birth "
                          name="reported_date"
                          onChange={(value: any) =>
                            setFieldValue("reported_date", value)
                          }
                          // value={values.reported_date}
                        />
                      </div>
                      <FormikText label="Employee Number" name="time" />
                    </div>
                    <div className="flex justify-between mb-[30px]">
                      <div className="min-w-[360px]">
                        <FormikDatePicker
                          inputClassName="datepicket_styles"
                          label="Start Date"
                          name="reported_date"
                          onChange={(value: any) =>
                            setFieldValue("reported_date", value)
                          }
                          // value={values.reported_date}
                        />
                      </div>
                      <div className="min-w-[360px]">
                        <FormikDatePicker
                          inputClassName="datepicket_styles"
                          label="Leave Date"
                          name="reported_date"
                          onChange={(value: any) =>
                            setFieldValue("reported_date", value)
                          }
                          // value={values.reported_date}
                        />
                      </div>
                      <FormikText label="License Number" name="time" />
                    </div>
                    <div className="flex justify-between mb-[30px]">
                      <FormikText label="License Class" name="vehicle" />
                      <FormikText
                        label="License State/Province/Religion"
                        name="vehicle"
                      />
                      <FormikText label="Hourly Labor Rate" name="time" />
                    </div>
                  </div> */}
                  <div className="mt-8">
                    <h2 className="text-[18px] font-[600] mb-5">Upload</h2>
                    <div>
                      {attachment.length < 5 && (
                        <div className="mt-8 input flex items-center bg-[#FCFCFC] pl-[35px] relative field w-full h-[100px] border rounded-[10px] border-dashed border-[#EBEBEB]">
                          <input
                            id="InputUpload"
                            type="file"
                            className="w-full h-full opacity-0 absolute cursor-pointer"
                            accept="image/*,application/pdf"
                            onChange={(e) => uploadFunction(e)}
                            multiple={true}
                          />
                          <img className="mr-5" src={uploadIcon} />
                          <div>
                            {" "}
                            <p className="text-[16px] font-medium ">
                              Click to Upload{" "}
                            </p>
                            <p className="text-[14px] text-[#757575]">
                              Maximum file size 5 MB
                            </p>
                          </div>
                        </div>
                      )}

                      <div className="mt-[24px]">
                        <div className="flex justify-between flex-wrap">
                          {attachment?.map((item: any, index: any) => (
                            <div
                              className={`border w-[49%] mb-5 rounded-[4px] bg-[#FAFAFA] ${
                                item?.type === "application/pdf" && ""
                              }  border-[#EBEBEB] px-[14px] py-[12px] flex justify-between items-center`}
                            >
                              <div className="flex  items-center">
                                <div
                                  className={`w-[32px] mr-[15px] bg-[#D9D9D9] ${
                                    item?.type === "application/pdf" &&
                                    "p-[3px]"
                                  }   h-[32px] rounded-[3px] `}
                                >
                                  <div className="w-[32px] mr-[15px] h-[32px] rounded-[3px] ">
                                    <a
                                      className=""
                                      href={
                                        !item?.is_uploaded
                                          ? item?.url
                                          : `${
                                              process.env
                                                .REACT_APP_S3_API_ENDPOINT
                                            }/${item.url}?${Date.now()}`
                                      }
                                      target="_blank"
                                    >
                                      {item?.type === "image/jpg" ||
                                      item?.type === "image/jpeg" ||
                                      item?.type === "image/png" ? (
                                        <img
                                          className="w-full h-full object-cover"
                                          src={
                                            !item?.is_uploaded
                                              ? item?.url
                                              : `${
                                                  process.env
                                                    .REACT_APP_S3_API_ENDPOINT
                                                }/${item.url}?${Date.now()}`
                                          }
                                        />
                                      ) : (
                                        <PdfImage />
                                      )}
                                    </a>
                                  </div>
                                </div>
                                <p className="font-medium text-[14px]">
                                  {item?.name}
                                </p>
                              </div>
                              <div
                                className="cursor-pointer"
                                onClick={() => deleteUploaded(index)}
                              >
                                <CancelUpload />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};
