import { Table } from "../../../atoms/Table/Table";
import { ListHeader } from "../../../molecules/list-header/ListHeader";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useVehicles } from "../context";
import { Link, useParams } from "react-router-dom";
import {
  getAging,
  getCurrentGroup,
  getCurrentVehicleFuelTypeFromKey,
  getCurrentVehicleTypesFromKey,
  getVehiclekey,
  getVehicleTypeLabel,
  selectOptionFormat,
  VehicleFuelType,
} from "../../../options/VehicleLoan";
import {
  getCurrentStatusFromKey,
  getStatusArray,
  VehicleStatuses,
} from "../../../options/VehicleStatus";
import { EditPenIcon } from "../../../icons/edit-pen";
import { SelectedBarProps } from "../../../atoms/ListingSelectedBar/ListingSelectedBar";
import { BreadCrumbs } from "../../../atoms/BreadCrumb/BreadCrumb";
import { PlusIcon } from "../../../icons";
import { Button } from "../../../atoms/Button/Button";
import { ListTrash } from "../../../icons/home";
import { Pagination } from "../../../atoms/Pagination/pagination";
import { useSearchParams } from "../../../hooks/useSearchParams";
import { SingleSelect } from "../../../atoms/Select/Select";
import { Drawer, Modal, Select, Tabs, Tooltip } from "antd";
import { colorCode, removeAndReturnFilter } from "../../../utils";
import {
  AgingVehicle,
  HealthyVehicle,
  OldVehicle,
} from "../../../icons/VehicleAging";
import { FiltersIcon } from "../../../icons/Pagination";
import { PermissionsGate } from "../../../atoms/PermissionComponent/PermissionGate";
import { MultiSelect } from "../../../atoms/Select/MultiSelect";
import { useCommonContext } from "../../../hooks/commonStateProvider";
const { confirm } = Modal;
const { Option } = Select;
export interface AllVehiclePageProps {
  group?: boolean;
}
const { TabPane } = Tabs;
export const AllVehiclePage: React.FC<AllVehiclePageProps> = ({
  group,
}: AllVehiclePageProps) => {
  let params = "";
  const [openNew, setOpenNew] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [data, setData]: any = useState<React.Key[]>([]);
  const [VehicleAge, setVehicleAge]: any = useState("");
  const { query, updateSearchParams }: any = useSearchParams();
  const [extraFilters, setExtraFilters]: any = useState({});
  const [filterDrawer, setFiltersDrawer] = useState(false);
  const [hasFilter, setHasFilter]: any = useState(false);
  const [ZeebaGroupList, setZeebaGroup] = useState([]);

  const { id }: any = useParams();
  const [filtersGroup, setFiltersGroup]: any = useState({
    status: "",
    vehicleType: "",
    fuelType: "",
    group: "",
  });
  const {
    actions: {
      getAllVehicles,
      DeleteVehicle,
      getAllGroups,
      getVehicleTypes,
      DeleteMultipleVehicles,
    },
    state: { documents, pagination, groups, vehicleTypes },
  } = useVehicles();
  const {
    state: { status },
    actions: { getAllStatus },
  } = useCommonContext();
  const columns: ColumnsType = [
    {
      title: "Name",
      dataIndex: "vehicleName",
      render: (value, record: any) => (
        <>
          <div className="flex items-center">
            {record?.financial?.purchaseDate &&
              record?.financial?.saleMillage && (
                <div className="aging mr-2 bg-[#F7F7F7] p-2 rounded-[5px]  ">
                  <div className="">
                    {getAging(
                      record?.financial?.purchaseDate,
                      record?.financial?.saleMillage
                    ) === "old" ? (
                      <Tooltip
                        className="cursor-pointer"
                        trigger={["hover", "focus", "click"]}
                        title="Old Vehicle"
                      >
                        <div className="flex items-center">
                          {" "}
                          <OldVehicle />{" "}
                        </div>
                      </Tooltip>
                    ) : getAging(
                        record?.financial?.purchaseDate,
                        record?.financial?.saleMillage
                      ) === "aging" ? (
                      <Tooltip
                        className="cursor-pointer"
                        trigger={["hover", "focus", "click"]}
                        title="Aging Vehicle"
                      >
                        <div className="flex items-center">
                          {" "}
                          <AgingVehicle />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        className="cursor-pointer"
                        trigger={["hover", "focus", "click"]}
                        title="Healthy Vehicle"
                      >
                        <div className="flex items-center">
                          {" "}
                          <HealthyVehicle />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
              )}

            <Link
              to={`/vehicles/${record.id}`}
              className="underline font-medium text-[#398CC0]"
            >
              {record.vehicleName}
            </Link>
          </div>
        </>
      ),
    },
    {
      title: "License Plate",
      dataIndex: "licensePlate",
      render: (value, record: any) => record.licensePlate,
    },
    {
      title: "VIN",
      dataIndex: "vin",
      // sorter: true,
    },
    {
      title: "Type",
      dataIndex: "vehicleType",
      render: (value, record: any) => (
        <div className="capitalize">{value.name || "N/A"}</div>
      ),
    },
    {
      title: "Make",
      dataIndex: "make",
      // sorter: true,
      render: (value, record: any) =>
        `${typeof value === "object" ? value?.name : value}`,
    },
    {
      title: "Model",
      dataIndex: "model",
      // sorter: true,
      render: (value, record: any) =>
        `${typeof value === "object" ? value?.name : value}`,
    },
    {
      title: "Year",
      dataIndex: "year",
      // sorter: true,
      render: (value, record: any) => `${value}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      // sorter: true,
      render: (value) => (
        <p className={`capitalize font-[600]`}>
          {value.name}
        </p>
      ),
    },
    {
      title: "Action",
      // sorter: true,
      render: (value, record: any) => (
        <div className="flex  items-center">
          <PermissionsGate action="edit" module="vehicle">
            <Link to={`/vehicles/edit/${record.id}/#info`}>
              <a className="underline flex items-center mr-[10px]">
                <EditPenIcon />
              </a>
            </Link>
          </PermissionsGate>

          <PermissionsGate action="delete" module="vehicle">
            <div
              className="cursor-pointer"
              onClick={() => {
                confirm({
                  title: "Warning",
                  content: "Are you sure want to deactivate this vehicle?",
                  onOk() {
                    DeleteVehicle(record.id, (res) => {
                      getAllVehicles();
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              <ListTrash />
            </div>
          </PermissionsGate>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (Object.keys(query).length !== 0) {
      getAllVehicles(false, query, id);
      setHasFilter(true);

      setExtraFilters({
        ...extraFilters,
        status: getStatusArray(query.status, status),
        fuelType: getCurrentVehicleFuelTypeFromKey(query.fuelType),
        vehicleType: getVehiclekey(query.vehicleType, vehicleTypes, false),
        group: getCurrentGroup(ZeebaGroupList, query.group),
      });
    } else {
      updateSearchParams({ page: 1, perPage: 10 });
    }
  }, [query]);
  useEffect(() => {
    getVehicleTypes();
    getAllStatus();
  }, []);
  useEffect(() => {
    let filter = { ...query };
    delete filter["page"];
    delete filter["perPage"];
    delete filter["sort"];
    const hasFilter = Object.keys(filter).length > 0;
    setHasFilter(hasFilter);
  }, [query]);

  useEffect(() => {
    if (documents) {
      documents.map((item: any, i: any) => {
        item["key"] = i.toString();
      });
      setData(documents);
      setSelectedRowKeys([]);
    }
  }, [documents]);
  useEffect(() => {}, [selectedRowKeys]);
  const onSelectChange = (record: any) => {
    if (selectedRowKeys.length > 0) {
      let indexValue = selectedRowKeys.findIndex((item) => item === record.key);
      if (indexValue === -1) {
        setSelectedRowKeys((prevstate) => [...prevstate, record.key]);
      } else {
        let items = [...selectedRowKeys];
        items.splice(indexValue, 1);
        setSelectedRowKeys(items);
      }
    } else {
      setSelectedRowKeys((prevstate) => [...prevstate, record.key]);
    }
  };

  const rowSelection = {
    // selectedRowKeys,
    // onSelect: onSelectChange,
    // onSelectAll: (selectedRows: any) => {
    //   if (selectedRows) {
    //     data.map((item: any) => {
    //       setSelectedRowKeys((prevstate) => [...prevstate, item.key]);
    //     });
    //   } else {
    //     setSelectedRowKeys([]);
    //   }
    // },
  };
  const updateSearch = () => {
    let queryParams: any = {};
    if (filtersGroup.status)
      queryParams["status"] = filtersGroup.status.map(
        (item: any) => item.value
      );
    if (filtersGroup.vehicleType)
      queryParams["vehicleType"] = filtersGroup.vehicleType.value;
    if (filtersGroup.fuelType)
      queryParams["fuelType"] = filtersGroup.fuelType.value;
    if (filtersGroup.group) queryParams["group"] = filtersGroup.group.value;
    return { ...query, ...queryParams };
  };

  useEffect(() => {
    getAllGroups();
  }, []);

  useEffect(() => {
    setZeebaGroup(selectOptionFormat(groups));
  }, [groups]);
  useEffect(() => {}, [extraFilters]);
  return (
    <div className="w-full h-full bg-white px-6">
      <div className="flex justify-between items-center py-[24px]">
        <BreadCrumbs />
        <div>
          <div className="flex flex-row-reverse gap-4">
            <PermissionsGate action="create" module="vehicle">
              <Link to={`/vehicles/add-vehicle`}>
                {" "}
                <Button className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center">
                  <span>
                    <PlusIcon />
                  </span>
                  Add Vehicle
                </Button>
              </Link>
            </PermissionsGate>
          </div>
        </div>
      </div>
      <Tabs
        defaultActiveKey="1"
        className="inner_overview_tab"
        onChange={(activeKey) => {
          if (activeKey === "1") {
            getAllVehicles(false);
            // updateSearchParams({ ...query, status: "" });
          }
          if (activeKey === "2") {
            getAllVehicles(false, {
              status: status.find((x: any) => x.label === "On Rent").value,
            });
          }
          if (activeKey === "3") {
            getAllVehicles(false, {
              status: status.find((x: any) => x.label === "Archieved").value,
            });
          }
        }}
      >
        <TabPane tab="All" key="1"></TabPane>
        <TabPane tab="Assigned" key="2"></TabPane>
        <TabPane tab="Archieved" key="3"></TabPane>
      </Tabs>
      <div className="card_style">
        <Drawer
          visible={filterDrawer}
          placement="right"
          onClose={() => setFiltersDrawer(false)}
          width="33%"
          title="Filters"
          className=""
        >
          <div className="mb-8">
            <div className="mb-5">
              <MultiSelect
                label="Zeeba Status"
                inputClassName="  text-[13px]"
                className=""
                placeholder="Zeeba Status"
                value={filtersGroup.status}
                options={status}
                onChange={(newValue: any) => {
                  setFiltersGroup({
                    ...filtersGroup,
                    status: newValue,
                  });
                }}
              />
            </div>

            <div className="mb-5">
              <SingleSelect
                inputClassName="adjust_select_height text-[13px]"
                className=""
                label="Zeeba Type"
                placeholder="Zeeba Type"
                value={filtersGroup.vehicleType}
                options={vehicleTypes}
                onChange={(newValue: any) => {
                  setFiltersGroup({
                    ...filtersGroup,
                    vehicleType: newValue,
                  });
                }}
              />
            </div>
            <div className="mb-5">
              <SingleSelect
                inputClassName="adjust_select_height text-[13px]"
                placeholder="Fuel Type"
                label="Fuel Type"
                value={filtersGroup.fuelType}
                options={VehicleFuelType}
                onChange={(newValue: any) => {
                  setFiltersGroup({
                    ...filtersGroup,
                    fuelType: newValue,
                  });
                }}
              />
            </div>
            <div className="mb-5">
              <SingleSelect
                options={ZeebaGroupList}
                placeholder="Client"
                label="Client/ Sub Client"
                value={filtersGroup.group}
                inputClassName="adjust_select_height text-[13px]"
                onChange={(newValue: any) => {
                  setFiltersGroup({
                    ...filtersGroup,
                    group: newValue,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex justify-end">
            {" "}
            <Button
              className="border mr-2  py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
              type="button"
              onClick={() => {
                setFiltersGroup({
                  status: "",
                  vehicleType: "",
                  fuelType: "",
                  group: null,
                });
                setExtraFilters({});
                updateSearchParams({ page: "1", perPage: "10" });
                setFiltersDrawer(false);
              }}
            >
              <span className="flex items-center text-[#FB6DB2]">
                Clear All
              </span>
            </Button>
            <Button
              type="submit"
              onClick={() => {
                setExtraFilters({
                  ...extraFilters,
                  status: filtersGroup.status,
                  vehicleType: filtersGroup.vehicleType,
                  fuelType: filtersGroup.fuelType,
                  group: filtersGroup.group,
                });
                updateSearchParams(updateSearch());
                setFiltersDrawer(false);
              }}
              className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
            >
              Apply
            </Button>
          </div>
        </Drawer>

        {/* Title, Search, Filter */}
        <ListHeader
          onNewClicked={() => {
            setOpenNew(true);
          }}
          onFilterChanged={(filter) => {}}
          onFilterClicked={() => {}}
          searchPlaceHolder="Search by Name, License Plate, VIN, Make, Model"
          pagination={pagination}
        >
          {/* {selectedRowKeys.length > 0 && (
            <SelectedBarProps
              deleteVehicles={(vehicleList) => {
                let vehiclesList: any = selectedRowKeys.map(
                  (x: any) => data[x]?.id
                );
                DeleteMultipleVehicles({ vehiclesList }, () => {
                  setSelectedRowKeys([]);
                  window.location.reload();
                });
              }}
              selectedItems={selectedRowKeys}
            />
          )} */}
          {!group && (
            <>
              <div className="ml-2 mr-2">
                <SingleSelect
                  inputClassName="adjust_select_height text-[13px]"
                  className="w-[180px]"
                  isClearable
                  placeholder="Zeeba Type"
                  value={
                    extraFilters["vehicleType"]
                      ? extraFilters["vehicleType"]
                      : null
                  }
                  options={vehicleTypes}
                  onChange={(newValue: any) => {
                    updateSearchParams(
                      newValue
                        ? {
                            ...query,
                            vehicleType: newValue && newValue.value,
                          }
                        : removeAndReturnFilter("vehicleType", query)
                    );
                  }}
                />
              </div>

              <div className="mr-2">
                <SingleSelect
                  options={ZeebaGroupList}
                  isClearable
                  className="w-[180px]"
                  placeholder="Client/SubClient"
                  name=""
                  value={extraFilters["group"] ? extraFilters["group"] : null}
                  inputClassName="adjust_select_height text-[13px]"
                  onChange={(newValue: any) => {
                    updateSearchParams(
                      newValue
                        ? {
                            ...query,
                            group: newValue && newValue.value,
                          }
                        : removeAndReturnFilter("group", query)
                    );
                  }}
                />
              </div>
              {hasFilter && (
                <div
                  onClick={() => {
                    setFiltersDrawer(true);
                    setFiltersGroup({
                      group: extraFilters["group"]
                        ? extraFilters["group"]
                        : null,
                      fuelType: extraFilters["fuelType"]
                        ? extraFilters["fuelType"]
                        : null,
                      vehicleType: extraFilters["vehicleType"]
                        ? extraFilters["vehicleType"]
                        : null,
                      status: extraFilters["status"]
                        ? extraFilters["status"]
                        : null,
                    });
                  }}
                  className="flex justify-center items-center rounded-[4px] cursor-pointer border w-[40px] min-w-[40px] h-[40px] border-[#f0f0f0]"
                >
                  {" "}
                  <FiltersIcon />
                </div>
              )}
              <MultiSelect
                label=""
                inputClassName="adjust_select_height custom_filter text-[13px]"
                className="max-w-[550px] mt-[20px]  adjust_select_height"
                isClearable
                placeholder="Zeeba Status"
                value={extraFilters["status"] ? extraFilters["status"] : null}
                options={status}
                onChange={(newValue: any) => {
                  updateSearchParams(
                    newValue
                      ? {
                          ...query,
                          status: newValue.map((item: any) => item.value),
                        }
                      : removeAndReturnFilter("status", query)
                  );
                }}
              />
            </>
          )}
        </ListHeader>
        <Table
          className="vehicle_table"
          columns={columns}
          dataSource={data}
          rowSelection={rowSelection}
          pagination={false}
        />
        <div className="mr-[24px] my-5">
          {" "}
          <Pagination
            pagination={pagination}
            onNext={() => {
              let newQuery: any = { ...query };
              newQuery.page = Number(newQuery.page) + 1;
              updateSearchParams({ ...newQuery });
            }}
            onPrevious={() => {
              let newQuery: any = { ...query };
              newQuery.page = Number(newQuery.page) - 1;
              updateSearchParams({ ...newQuery });
            }}
          />
        </div>
      </div>
    </div>
  );
};
