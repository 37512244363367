import { useEffect, useRef, useState } from "react";
import { Alert, Empty, Space, Tabs, Modal, Drawer, message, Spin } from "antd";
import { BreadCrumbs } from "../../atoms/BreadCrumb/BreadCrumb";
import { SingleSelect } from "../../atoms/Select/Select";
import { AddFlipped } from "./AddFlipped";
import { AddPipeline } from "./AddPipeline";
import { AddProgress } from "./AddProgress";
import uploadIcon from "../../icons/designIcons/uploadIcon.svg";
import { VinsList } from "./VinsList";
import { Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  ErrorModal,
  getCurrentStatusFromKey,
  procurementStatus,
  statusBasesOnCurrentStatus,
} from "./procurementLoad";
import { useProcurement } from "./context";
import { useParams } from "react-router";
import { Button } from "../../atoms/Button/Button";
import { CancelUpload, PdfImage } from "../../icons/UploadIcons";

import MyEditor from "./editor";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { returnPriceValue, ShowDateFormat } from "../../utils";
import { FixedModal } from "../../atoms/FixedModal/fixedModal";
import { Comments } from "../../atoms/Comments/Comments";
import { useCommonContext } from "../../hooks/commonStateProvider";
const { confirm } = Modal;
const { TabPane } = Tabs;
export interface ProcurementDetailsProps {}
export const ProcurementDetail: React.FC<ProcurementDetailsProps> = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
  const [flipDragger, setFlipDragger] = useState(false);
  const [progressDragger, setProgressDragger] = useState(false);
  const [pipelineDragger, setPipelineDragger] = useState({
    visible: false,
    isNew: false,
  });
  const [currentStatus, setCurrentStatus]: any = useState({});
  const [uploadVins, setUploadVins] = useState(false);
  const [notes, setnotes] = useState("");
  const [dummyNotes, setDummyNotes] = useState("");
  const [isNotesEditable, setEditableNotes] = useState(false);
  const { id }: any = useParams();
  const [file, setFile]: any = useState({});
  const [formData, setFormData]: any = useState();
  const [isFleeted, setFleeted] = useState(false);
  const [fixedModal, setFixedModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const editorReference = useRef();
  const {
    actions: {
      getOneDetail,
      updateOne,
      uploadDocument,
      getVinsList,
      inFleetVehicles,
    },
    state: { procurement, vinsList },
  } = useProcurement();

  const {
    actions: { getAllComments, emptyState },
  } = useCommonContext();
  useEffect(() => {
    if (!uploadVins) {
      setFile({});
    }
  }, [uploadVins]);
  useEffect(() => {
    setFleeted(false);
    getOneDetail(id);
    getVinsList(id, true);
    return () => {
      emptyState();
    };
  }, []);

  useEffect(() => {
    if (vinsList) {
      vinsList?.data?.map((item: any) => {
        if (item.status === "infleeted") setFleeted(true);
      });
    }
  }, [vinsList]);
  useEffect(() => {
    setCurrentStatus(getCurrentStatusFromKey(procurement.status));
    setnotes(procurement.notes ? procurement.notes : "");
    setDummyNotes(procurement.notes ? procurement.notes : "");
  }, [procurement]);

  useEffect(() => {
    setCurrentStatus(getCurrentStatusFromKey(procurement.status));
  }, [currentStatus]);

  const uploadFunction = (e: any) => {
    let file = e.target.files[0];
    // SetSelectedFile(file);
    if (file.type !== "text/csv") {
      message.error("Please upload only CSV file");
    } else if (file.size > 5 * 1000000) {
      message.error("Couldn't upload large file");
    } else {
      let formedData = new FormData();
      formedData.append("file", file, file.name);
      setFormData(formedData);

      // uploadDocument(id, formData, (data) => {});
      let updatedData = {
        name: file.name,
        type: file.type,
      };

      setFile(updatedData);
    }
  };
  const updateNotes = () => {
    let data = { notes: dummyNotes ? dummyNotes : "" };
    updateOne(id, data, () => {
      setEditableNotes(false);
    });
  };

  const checkStatus = (changedStatus: any) => {
    let currentStatus = changedStatus.value;

    if (currentStatus === procurement.status) return;
    else if (
      ((currentStatus === "flipped" || currentStatus === "keeping") &&
        !procurement.leadInfo) ||
      ((currentStatus === "flipped" || currentStatus === "keeping") &&
        vinsList?.data?.length === 0)
    ) {
      ErrorModal("");
      setCurrentStatus(getCurrentStatusFromKey(procurement.status));
    } else if (
      currentStatus == "archieved" &&
      procurement.status === "flipped" &&
      !procurement.flipped
    ) {
      ErrorModal("toClosedError");
    } else {
      setCurrentStatus(changedStatus);
      changeStatusSuccessfully(currentStatus);
    }
  };

  const changeStatusSuccessfully = (changedStatus: any) => {
    confirm({
      title: "Warning",
      content: "Are you sure want to update the status?",
      onOk() {
        updateOne(id, { status: changedStatus }, () => {
          getOneDetail(id);
          window.location.reload();
        });
      },
      onCancel() {
        setCurrentStatus(getCurrentStatusFromKey(procurement.status));
      },
    });
  };

  return (
    <>
      {pipelineDragger.visible && (
        <AddPipeline
          draggerKey={pipelineDragger}
          setDragger={setPipelineDragger}
        />
      )}

      {flipDragger && (
        <AddFlipped dragger={flipDragger} setDragger={setFlipDragger} />
      )}
      {progressDragger && (
        <AddProgress
          dragger={progressDragger}
          setDragger={setProgressDragger}
        />
      )}
      <Drawer
        visible={uploadVins}
        placement="right"
        onClose={() => {
          setUploadVins(false);
        }}
        width="33%"
        title="Upload Vins Document"
      >
        <h3 className="text-[14px] font-medium mb-6">
          Upload Vehicle Informations
        </h3>
        <div className="mb-[30px]">
          <a
            className="text-[14px]  font-medium underline text-[#00AEEF]"
            href="https://zeeba-vans-assets.s3.amazonaws.com/vehicle-sample.csv"
          >
            Click here to download the Sample File for your Reference
          </a>
        </div>
        {Object.keys(file).length === 0 && (
          <div>
            <div className="border border-dashed relative border-[#EBEBEB] rounded-[10px] bg-[#FCFCFC]  p-[30px] flex ">
              <input
                type="file"
                id="InputUpload"
                className="w-full h-full opacity-0 absolute cursor-pointer "
                onChange={(e) => uploadFunction(e)}
                accept=".csv"
              />

              <img className=" mr-3 " src={uploadIcon} />
              <div>
                <p className="text-[16px] font-medium mb-[12px]">
                  Click to Upload or Drag and Drop
                </p>
                <p className="text-[14px] text-[#757575]">
                  Maximum file size 5 MB
                </p>
              </div>
            </div>
          </div>
        )}
        {Object.keys(file).length > 0 && (
          <div className="border mt-[22px] rounded-[4px]  bg-[#FAFAFA] border-[#EBEBEB] px-[14px] py-[12px] flex justify-between items-center">
            <div className="flex items-center">
              <div className="w-[32px] mr-[15px] bg-[#D9D9D9] h-[32px] rounded-[3px] p-[3px]">
                <PdfImage />
              </div>
              <p className="font-medium text-[14px]">{file.name}</p>
            </div>
            <div className="cursor-pointer" onClick={() => setFile({})}>
              <CancelUpload />
            </div>
          </div>
        )}
        <div className="mt-10">
          <div className="flex flex-row-reverse gap-4">
            <Button
              type="submit"
              className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
              onClick={() => {
                setSpinner(true);
                uploadDocument(id, formData, (data) => {
                  if (!data) {
                    setSpinner(false);
                  } else {
                    setSpinner(false);
                    setFile({});
                    setUploadVins(false);
                    setFormData({});
                  }
                });
              }}
              disabled={spinner}
            >
              <>
                {spinner && (
                  <Spin className="btn-spinner" indicator={antIcon} />
                )}
                Upload
              </>
            </Button>{" "}
            {!spinner && (
              <Button
                className="border  py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
                type="reset"
                onClick={() => {
                  setUploadVins(false);
                }}
              >
                <span className="flex items-center text-[#FB6DB2]">Cancel</span>
              </Button>
            )}
          </div>
        </div>
      </Drawer>

      <div className="px-6 ">
        <FixedModal isVisible={fixedModal} />
        <div className="flex justify-between items-center py-[24px]">
          <BreadCrumbs showTitle={procurement ? procurement.title : ""} />
          <div className="w-[180px]">
            <SingleSelect
              placeholder="Procurement Status"
              inputClassName="adjust_select_height text-[13px]"
              value={currentStatus}
              options={statusBasesOnCurrentStatus(
                currentStatus || { value: "pipeline" }
              )}
              onChange={(newValue: any) => {
                checkStatus(newValue);
              }}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="w-[43%]">
            <div className="common_card w-full  mb-[24px]">
              <div className="flex justify-between items-center p-[24px] pb-[22px]">
                <h2 className="font-medium text-[18px]">Basic Information</h2>
                {procurement?.status !== "archieved" && (
                  <button
                    className="text-[#FB6DB2] font-semibold text-[15px] "
                    onClick={() =>
                      setPipelineDragger({ visible: true, isNew: false })
                    }
                  >
                    Edit
                  </button>
                )}
              </div>
              <div className="plain_detail">
                <div className="V_detail_header">Title of the deal</div>
                <div className="V_detail_data">{procurement?.title}</div>
              </div>
              <div className="striped_detail">
                <div className="V_detail_header">Buyer agent</div>
                <div className="V_detail_data">{procurement?.buyer}</div>
              </div>
              <div className="striped_detail">
                <div className="V_detail_header">Arrival Date</div>
                <div className="V_detail_data">{ShowDateFormat(procurement?.arivalDate)}</div>
              </div>
              <div className="plain_detail">
                <div className="V_detail_header">No of vehicles</div>
                <div className="V_detail_data">{procurement?.noOfUnits}</div>
              </div>
              <div className="striped_detail">
                <div className="V_detail_header">Make</div>
                <div className="V_detail_data">{procurement?.make?.name}</div>
              </div>
              <div className="plain_detail">
                <div className="V_detail_header">Model</div>
                <div className="V_detail_data">{procurement?.model?.name}</div>
              </div>
              <div className="striped_detail">
                <div className="V_detail_header">Price/Unit</div>
                <div className="V_detail_data">
                  {returnPriceValue(procurement?.priceOfUnits)}
                </div>
              </div>
              <div className="plain_detail">
                <div className="V_detail_header">Assigned To</div>
                <div className="V_detail_data">
                  {procurement?.assignedTo?.firstName} &nbsp;
                  {procurement?.assignedTo?.lastName}
                </div>
              </div>
            </div>
            {procurement.status !== "pipeline" && (
              <div className="common_card w-full  mb-[24px]">
                <div className="flex justify-between items-center p-[24px] pb-[22px]">
                  <h2 className="font-medium text-[18px]">More Information</h2>
                  {procurement?.status !== "archieved" && (
                    <button
                      className="text-[#FB6DB2] font-semibold text-[15px] "
                      onClick={() => setProgressDragger(true)}
                    >
                      Edit
                    </button>
                  )}
                </div>
                {procurement && (
                  <div>
                    <div className="plain_detail">
                      <div className="V_detail_header">Loaner</div>
                      <div className="V_detail_data">
                        {procurement.leadInfo
                          ? procurement.leadInfo.loaner
                          : "N/A"}
                      </div>
                    </div>
                    <div className="striped_detail">
                      <div className="V_detail_header">Down Payment /Unit</div>
                      <div className="V_detail_data">
                        {procurement.leadInfo
                          ? returnPriceValue(procurement.leadInfo.downPayment)
                          : "N/A"}
                      </div>
                    </div>
                    <div className="plain_detail">
                      <div className="V_detail_header">Total Down Payment</div>
                      <div className="V_detail_data">
                        {procurement.leadInfo
                          ? returnPriceValue(
                              procurement.leadInfo.downPayment *
                                procurement?.noOfUnits
                            )
                          : "N/A"}
                      </div>
                    </div>
                    <div className="striped_detail">
                      <div className="V_detail_header">Vehicle Location</div>
                      <div className="V_detail_data">
                        {procurement.leadInfo
                          ? procurement.leadInfo.location
                          : "N/A"}
                      </div>
                    </div>
                    <div className="plain_detail">
                      <div className="V_detail_header">
                        Targeted Launch date
                      </div>
                      <div className="V_detail_data">
                        {procurement.leadInfo
                          ? ShowDateFormat(procurement.leadInfo.launchDate)
                          : "N/A"}
                      </div>
                    </div>
                    <div className="striped_detail">
                      <div className="V_detail_header">MSRP</div>
                      <div className="V_detail_data">
                        {procurement.leadInfo
                          ? returnPriceValue(procurement.leadInfo.msrp)
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="w-[56%]">
            <div className="common_card w-full mb-[24px] vehicle_detial_tab">
              <Tabs
                defaultActiveKey="1"
                className="inner_overview_tab"
                onChange={(activeKey) => {
                  if (activeKey === "2") {
                    getAllComments(id);
                  }
                }}
              >
                <TabPane tab="Notes" key="1">
                  <div className="">
                    {" "}
                    <div className=" w-full  mb-[24px] h-[530px]">
                      <div className="flex justify-between items-center px-[24px] py-[25px] bg-[#FAFAFA]">
                        <h2 className="text-[16px] font-medium ">Notes</h2>
                        {isNotesEditable ? (
                          <button
                            className="text-[#FB6DB2] font-semibold text-[15px] "
                            onClick={() => {
                              updateNotes();
                            }}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="text-[#FB6DB2] font-semibold text-[15px] "
                            onClick={() => {
                              setEditableNotes(true);
                            }}
                          >
                            Edit
                          </button>
                        )}
                      </div>
                      {procurement.notes === "" && !isNotesEditable ? (
                        <div className="p-[24px] flex justify-center items-center w-full h-full">
                          <p className="text-[#808080] text-[13px] font-medium w-[50%] text-center">
                            A running notes for the current procurement
                            activity. This notes will be available on all the
                            stages of the pipeline.
                          </p>
                        </div>
                      ) : (
                        <div id="container" className="break-words">
                          <MyEditor
                            editorReference={editorReference}
                            notes={notes}
                            isNotes={notes ? true : false}
                            isNotesEditable={isNotesEditable}
                            setnotes={setnotes}
                            dummyNotes={dummyNotes}
                            setDummyNotes={setDummyNotes}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Comments" key="2">
                  <Comments id={id} model="procurement" />
                </TabPane>
              </Tabs>
            </div>

            {(procurement.status === "flipped" || procurement?.flipped) && (
              <div className="common_card w-full  mb-[24px]">
                <div className="flex justify-between items-center p-[24px] pb-[22px]">
                  <h2 className="font-medium text-[18px]">
                    Flipped Informations
                  </h2>
                  {procurement.status !== "archieved"}
                  <button
                    className="text-[#FB6DB2] font-semibold text-[15px] "
                    onClick={() => setFlipDragger(true)}
                  >
                    Edit
                  </button>
                </div>

                <div>
                  <div className="plain_detail">
                    <div className="V_detail_header">Purchaser</div>
                    <div className="V_detail_data">
                      {procurement.flipped
                        ? procurement.flipped.purchaser
                        : "N/A"}
                    </div>
                  </div>
                  <div className="striped_detail">
                    <div className="V_detail_header">Price per vehicle</div>
                    <div className="V_detail_data">
                      {procurement.flipped
                        ? returnPriceValue(procurement.flipped.pricePerVehicle)
                        : "N/A"}
                    </div>
                  </div>
                  <div className="plain_detail">
                    <div className="V_detail_header">Total sales</div>
                    <div className="V_detail_data">
                      {procurement.flipped
                        ? returnPriceValue(procurement.flipped.sales)
                        : "N/A"}
                    </div>
                  </div>
                  <div className="striped_detail">
                    <div className="V_detail_header">Total Profit</div>
                    <div className="V_detail_data">
                      {procurement.flipped
                        ? returnPriceValue(procurement.flipped.profit)
                        : "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {procurement.status !== "pipeline" && (
          <div>
            <div className="common_card w-full  mb-[24px]">
              <div className="flex justify-between items-center px-[24px] py-[25px] bg-[#FAFAFA]">
                <h2 className="text-[16px] font-medium">List Of Vehicles</h2>
                {procurement.status !== "archieved" && (
                  <>
                    {vinsList?.data?.length > 0 ? (
                      <button
                        className={`text-[#FB6DB2] font-semibold text-[15px]`}
                        onClick={() => setUploadVins(true)}
                        disabled={isFleeted}
                      >
                        {isFleeted ? (
                          <Tooltip
                            className="cursor-pointer"
                            trigger={["hover", "focus", "click"]}
                            title="You cannot Upload Vehicle as some of your vehicles are infleeted"
                          >
                            <span className="text-[grey]"> Re Upload</span>
                          </Tooltip>
                        ) : (
                          <span>Re Upload</span>
                        )}
                      </button>
                    ) : (
                      <button
                        className="text-[#FB6DB2] font-semibold text-[15px]"
                        onClick={() => setUploadVins(true)}
                      >
                        Upload
                      </button>
                    )}
                  </>
                )}

                {/* <button className="text-[#FB6DB2] font-semibold text-[15px] ">
                Reupload
              </button> */}
              </div>
              {vinsList?.data?.length ? (
                <VinsList
                  setFixedModal={setFixedModal}
                  onInFleetVehiclesClicked={(vehiclesList) => {
                    inFleetVehicles(id, { vehiclesList }, () => {
                      setFixedModal(false);
                    });
                  }}
                />
              ) : (
                <div className="h-[40vh] justify-center flex items-center">
                  <Empty />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
