import { Drawer } from "antd";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import * as Yup from "yup";
import { Button } from "../../atoms/Button/Button";
import { FormikCreateSinglSelect } from "../../atoms/Select/CretableSelect";
import { FormikSinglSelect } from "../../atoms/Select/Select";
import { FormikText } from "../../atoms/Text/Text";
import { selectOptionFormat } from "../../options/VehicleLoan";
import { setSelectedValue } from "../../utils";
import { useContract } from "../schedule/planning/context";
import { useUsers } from "../user-management/context";
import { useProcurement } from "./context";
import { FormikDatePicker } from "../../atoms/DatePicker/DatePicker";

export interface AddPipelineProps {
  draggerKey?: any;
  setDragger?: any;
  data?: any;
  assignData?: any; //key to get data from assign vehicles details
}
export const AddPipeline: React.FC<AddPipelineProps> = ({
  draggerKey,
  setDragger,
  data,
  assignData,
}) => {
  const [initialValues, setInitialValues]: any = useState({
    title: "",
    year: "",
    buyer: "",
    make: "",
    model: "",
    noOfUnits: "",
    priceOfUnits: "",
    assignedTo: "",
    arivalDate:""
  });
  const [usersList, setUsers] = useState([]);
  const [makeDropDown, setMake] = useState([]);
  const [modelDropDown, setModel] = useState([]);
  const { id }: any = useParams();
  const {
    actions: {
      createOne,
      getAllProcurement,
      updateOne,
      getOneDetail,
      getAllUsers,
      getAllMakeModel,
    },
    state: { procurement, users },
  } = useProcurement();

  const {
    state: { contract },
  } = useContract();

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    getAllMakeModel({ type: "make" }, (res: any) => {
      setMake(selectOptionFormat(res));
    });
  }, []);

  useEffect(() => {
    let newArray: any = [];
    if (users) {
      users?.data?.map((item: any) => {
        let obj = {
          label: `${item.firstName} ${item.lastName}`,
          value: item.id,
        };
        newArray.push(obj);
      });
      setUsers(newArray);
    }
  }, [users]);

  useEffect(() => {
    if (!draggerKey.isNew && procurement) {
      setInitialValues({
        ...initialValues,
        title: procurement.title,
        buyer: procurement.buyer,
        noOfUnits: procurement.noOfUnits,
        priceOfUnits: procurement.priceOfUnits,
        year: procurement.year,
        make: { label: procurement?.make?.name, value: procurement?.make?.id },
        model: {
          label: procurement?.model?.name,
          value: procurement?.model?.id,
        },
        assignedTo: setSelectedValue(procurement.assignedTo),
      });
    }
  }, [procurement]);

  useEffect(() => {
    if (assignData && contract) {
      setInitialValues({
        ...initialValues,
        noOfUnits: assignData.wanted_vehicle,
        assignedTo: setSelectedValue(contract.user),
        title: contract.name,
      });
    }
  }, [assignData]);
  useEffect(() => {
    if (draggerKey.isNew && !assignData) {
      setInitialValues({
        title: "",
        year: "",
        buyer: "",
        make: "",
        model: "",
        noOfUnits: "",
        priceOfUnits: "",
        assignedTo: "",
        arivalDate:""
      });
    }
  }, [draggerKey]);
  return (
    <>
      <Drawer
        open={draggerKey.visible}
        placement="right"
        onClose={() => {
          setDragger(false);
          // setInitialValues(initialValues);
        }}
        width="33%"
        title="Add New Lead"
      >
        <div>
          <Formik
            // innerRef={formRef}
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object({
              title: Yup.string().required("Title  is required"),
              buyer: Yup.string().required("Buyer is required"),
              year: Yup.string().required("Year is required"),
              make: Yup.mixed().required("Make is required"),
              model: Yup.mixed().required("Model is required"),
              noOfUnits: Yup.string().required("Vehicles Count is required"),
              priceOfUnits: Yup.string().required("Price is required"),
              assignedTo: Yup.mixed().required("Assigne To is required"),
            })}
            onSubmit={(values: any, actions) => {
              if (id && !assignData) {
                updateOne(
                  id,
                  {
                    ...values,
                    year: parseInt(values.year),
                    assignedTo: values.assignedTo.value,
                    make: values.make.value,
                    model: values.model.value,
                  },
                  () => {
                    setDragger(false);
                    getOneDetail(data.id);
                  }
                );
              } else {
                createOne(
                  {
                    ...values,
                    year: parseInt(values.year),
                    noOfUnits: parseInt(values.noOfUnits),
                    assignedTo: values.assignedTo.value,
                    make: values.make.value,
                    model: values.model.value,
                  },
                  () => {
                    setDragger(false);
                    getAllProcurement();
                  }
                );
              }
            }}
          >
            {({ values, setFieldValue, setErrors }) => (
              <Form>
                {" "}
                <div className="mb-4">
                  <FormikText
                    label="Title of the deal"
                    name="title"
                    maxLength={35}
                    must={true}
                  />
                </div>
                <div className="mb-4">
                  <FormikText
                    label="Buyer agent"
                    name="buyer"
                    maxLength={35}
                    must={true}
                  />
                </div>
                <div className="mb-4">
                  <FormikDatePicker
                    inputClassName="datepicket_styles"
                    label="Arrival Date"
                    name="arivalDate"
                    onChange={(value: any) =>
                      setFieldValue("arivalDate", value)
                    }
                  // value={values.reported_date}
                  />
                </div>
                <div className="mb-4">
                  <FormikText
                    label="Number of vehicles"
                    name="noOfUnits"
                    type="number"
                    must={true}
                  />
                </div>
                <div className="mb-4">
                  <FormikText
                    label="Year"
                    name="year"
                    type="number"
                    must={true}
                  />
                </div>
                <div className="mb-4">
                  <FormikSinglSelect
                    must={true}
                    isClearable
                    options={makeDropDown}
                    label="Zeeba Make"
                    name="make"
                    inputClassName="adjust_select_height"
                    onChange={(newValue: any) => {
                      setFieldValue("make", newValue);
                      setFieldValue("model", "");
                      getAllMakeModel(
                        {
                          parent: newValue.value,
                        },
                        (res: any) => {
                          setModel(selectOptionFormat(res));
                        }
                      );
                    }}
                  />
                </div>
                <div className="mb-4">
                  <FormikSinglSelect
                    must={true}
                    isClearable
                    options={modelDropDown}
                    label="Zeeba Model"
                    name="model"
                    inputClassName="adjust_select_height"
                    onChange={(newValue: any) => {
                      setFieldValue("model", newValue);
                    }}
                  />
                </div>
                <div className="mb-4">
                  <FormikText
                    label="Price/Unit"
                    type="number"
                    name="priceOfUnits"
                    must={true}
                  />
                </div>
                <FormikSinglSelect
                  isClearable
                  must={true}
                  options={usersList}
                  label="Assigned To"
                  name="assignedTo"
                  inputClassName="adjust_select_height"
                  onChange={(newValue) => {
                    setFieldValue("assignedTo", newValue);
                  }}
                />
                <div className="mt-10">
                  <div className="flex flex-row-reverse gap-4">
                    {" "}
                    <Button
                      type="submit"
                      className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
                    >
                      Save Lead
                    </Button>
                    <Button
                      className="border  py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
                      type="reset"
                      onClick={() => {
                        setErrors({});
                        setDragger(false);
                      }}
                    >
                      <span className="flex items-center text-[#FB6DB2]">
                        Cancel
                      </span>
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Drawer>
    </>
  );
};
