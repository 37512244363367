import { Divider, Image } from "antd";
import { useEffect, useState } from "react";

interface ViewInspectionResponseProps {
  components: any;
  responses: any;
}

const returnComponent = (component: any, response: any) => {
  if (
    component.type === "media/image/single" ||
    component.type === "media/image/multi"
  ) {
    return (
      <div className="mb-5">
        <p className="font-medium text-[14px] mb-[5px]">{component.title}</p>
        <p className="font-medium text-[12px] mb-[12px] text-[#474040]">{component.description}</p>
        <div className="flex flex-wrap gap-[20px]">
          <Image.PreviewGroup>
            {response[component.key.toLowerCase().replace(/-/g, "")].map(
              (image: string) => (
                <Image className="w-[100px] h-[100px]" src={`${process.env.REACT_APP_S3_API_ENDPOINT}/${image}`} />
              )
            )}
          </Image.PreviewGroup>
        </div>
      </div>
    );
  } else if (component.type === "select" || component.type === "radio") {
    return (
      <div className="form-element mb-5">
         <p className="font-medium text-[14px] mb-[5px]">{component.title}</p>
        <p className="font-medium text-[12px] mb-[12px] text-[#474040]">{component.description}</p>
        <h3 className="font-semibold text-base mb-2 capitalize">
          {response[component.key.toLowerCase().replace(/-/g, "")].label || response[component.key.toLowerCase().replace(/-/g, "")].value}
        </h3>
      </div>
    );
  }  else if (component.type === "multiselect") {
    return (
      <div className="form-element mb-5">
         <p className="font-medium text-[14px] mb-[5px]">{component.title}</p>
        <p className="font-medium text-[12px] mb-[12px] text-[#474040]">{component.description}</p>
        <h3 className="font-semibold text-base mb-2 capitalize">
          {response[component.key.toLowerCase().replace(/-/g, "")].map((x:any)=> x.label).join(",")}
        </h3>
      </div>
    );
  } else {
    return (
      <div className="form-element mb-5">
        <p className="font-medium text-[14px] mb-[5px]">{component.title}</p>
        <p className="font-medium text-[12px] mb-[12px] text-[#474040]">{component.description}</p>
        <h3 className="font-semibold text-base mb-2 capitalize">
          {response[component.key.toLowerCase().replace(/-/g, "")]}
        </h3>
      </div>
    );
  }
};

const returnTransformedResponse = (response: any) => {
  const newResponse: any = {};
  Object.keys(response).forEach((x) => {
    newResponse[`${x.toLocaleLowerCase()}`] = response[x];
  });
  return newResponse;
};
export const ViewInspectionResponse: React.FC<ViewInspectionResponseProps> = ({
  components,
  responses,
}) => {
  const [newResponse, setNewResponse] = useState(
    returnTransformedResponse(responses)
  );

  return (
    <div className="p-2">
      {components.map((c: any) => (
        <div className="p-2 border mb-4">
          <h3 className="font-semibold text-base">{c.header}</h3>
          <Divider />
          {c.components.map((sc: any) => {
            return returnComponent(sc, newResponse);
          })}
        </div>
      ))}
    </div>
  );
};
