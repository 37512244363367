import { Checkbox } from "antd";
import Table from "antd/es/table";
import { ColumnsType } from "antd/es/table";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";
import { ListHeader } from "../../../../../molecules/list-header/ListHeader";
import dummyImage from "../../../../../icons/designIcons/dummyImage.svg";
import { useEffect, useState } from "react";
import { useContract } from "../../context";
import { useSearchParams } from "../../../../../hooks/useSearchParams";
import { Search } from "../../../../../atoms/Search/Search";
import { SendingDataFormat } from "../../../ScheduleLoad";
import user from "../../../../../icons/designIcons/dummycar.png";
import { getCurrentVehicleTypesFromKey } from "../../../../../options/VehicleLoan";
import { PlusIcon } from "../../../../../icons";
import { Button } from "../../../../../atoms/Button/Button";
import { AddPipeline } from "../../../../procurement/AddPipeline";
export interface AssignVehicleProps {
  selectedRowKeys?: any;
  setSelectedRowKeys?: any;
  selectedVehicle?: any;
}

export const AssignVehicle: React.FC<AssignVehicleProps> = ({
  selectedRowKeys,
  setSelectedRowKeys,
  selectedVehicle,
}) => {
  const [data, setData]: any = useState([]);
  const [search, setSearch] = useState("");
  const [pipelineDragger, setPipelineDragger] = useState({
    visible: false,
    isNew: false,
  });
  useEffect(() => {}, [selectedRowKeys]);

  const handleKeyDown = ({ key, target }: any) => {
    if (key === "Enter") {
      getAvailableVehicles(
        SendingDataFormat(contract.contractStartDate),
        SendingDataFormat(contract.contractEndDate),
        selectedVehicle.vehicleType.value,
        search
      );
    }
  };

  const {
    actions: {
      getAllContacts,
      getOneContract,
      getAllGroups,
      getAvailability,
      getAllUsers,
      createOne,
      updateOne,
      clearOne,
      getAvailableVehicles,
    },
    state: {
      documents,
      vehicle,
      pagination,
      users,
      contacts,
      groups,
      contract,
    },
  } = useContract();
  const onSelectChange = (record: any) => {
    if (selectedRowKeys.length > 0) {
      let indexValue = selectedRowKeys.findIndex(
        (item: any) => item.key === record.key
      );
      if (indexValue === -1) {
        setSelectedRowKeys((prevstate: any) => [...prevstate, record]);
      } else {
        let items = [...selectedRowKeys];
        items.splice(indexValue, 1);
        setSelectedRowKeys(items);
      }
    } else {
      setSelectedRowKeys((prevstate: any) => [...prevstate, record]);
    }
  };

  const rowSelection = {
    // selectedRowKeys,
    // getCheckboxProps: (record: any) => {
    //   const rowIndex = data.findIndex((item: any) => item.key === record.key);
    //   return {
    //     disabled: rowIndex < 1, //disable the first 4 rows only
    //   };
    // },
    onSelect: onSelectChange,

    // onSelectAll: (selectedRows: any) => {
    //   if (selectedRows) {
    //     data.map((item: any) => {
    //       setSelectedRowKeys((prevstate) => [...prevstate, item.key]);
    //     });
    //   } else {
    //     setSelectedRowKeys([]);
    //   }
    // },
  };

  useEffect(() => {
    if (vehicle) {
      vehicle?.map((item: any, i: any) => {
        item["key"] = i.toString();
        item.is_enabled = false;
      });
      setData(vehicle);
      setSelectedRowKeys([]);
    }
  }, [vehicle]);
  useEffect(() => {}, [selectedRowKeys]);

  const checkAddVehicle = () => {
    if (data.length === 0) return true;
    else if (selectedVehicle.totalAssigns === selectedVehicle?.noOfUnits)
      return false;
    else if (
      selectedVehicle.noOfUnits - selectedVehicle.totalAssigns >
      data.length
    )
      return true;
    else return false;
  };
  const checkDisableState = (item: any) => {
    if (item.is_enabled) {
      return false;
    } else {
      if (
        selectedRowKeys.length ===
        selectedVehicle.noOfUnits - selectedVehicle.totalAssigns
      ) {
        return true;
      } else {
        return false;
      }
    }
  };
  return (
    <>
      {pipelineDragger.visible && (
        <AddPipeline
          draggerKey={pipelineDragger}
          assignData={{
            ...selectedVehicle,
            wanted_vehicle:
              selectedVehicle.totalAssigns === 0
                ? selectedVehicle.noOfUnits - data.length
                : selectedVehicle.noOfUnits -
                  selectedVehicle.totalAssigns -
                  data.length,
          }}
          setDragger={setPipelineDragger}
        />
      )}
      <div className="mb-[14px]">
        <input
          className="w-[450px] border border-[#EDEDED] outline-none rounded-[5px] px-[13px] py-[10px]"
          placeholder={"Search by Name"}
          value={search}
          onChange={({ target }) => {
            setSearch(target.value);
          }}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div>
        {selectedRowKeys?.length > 0 && (
          <div className="flex justify-between items-center mb-[14px]">
            <div className="w-[10%]">
              <div className="bg-[#F9F9F9] rounded-[4px]   p-[10px]  text-[10px]  font-medium">
                <p className="text-[10px] text-[#5F5F5F] mb-1 font-medium">
                  Selected Vehicles
                </p>
                <p className="text-[#A4A1A1] text-center">
                  ({selectedRowKeys?.length} out of {data?.length})
                </p>
                <p></p>
              </div>
            </div>
            <div className="border w-[88%] flex-wrap px-2  rounded-[65px] border-[#EDEDED]  flex">
              {selectedRowKeys?.length > 0 &&
                selectedRowKeys.map((item: any, index: any) => (
                  <div className="bg-[#FFD9ED] mx-2  my-[8px] flex items-center p-[7px] rounded-[35px]">
                    <p className="font-medium text-[12px] mr-2">
                      {item.vehicleName}
                    </p>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        selectedRowKeys.splice(index, 1);
                        setSelectedRowKeys(selectedRowKeys);
                        data[item.key].is_enabled = false;
                        setData([...data]);
                      }}
                    >
                      <IoIosCloseCircleOutline />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        <div>
          {data?.length > 0 ? (
            <div>
              {data.map((value: any, index: any) => (
                <div className="table_card_box justify-between flex mb-[20px] p-[14px]">
                  <Checkbox
                    className="mt-[20px]"
                    checked={value.is_enabled}
                    disabled={checkDisableState(value)}
                    onChange={(e) => {
                      data[index].is_enabled = !data[index].is_enabled;
                      setData([...data]);
                      onSelectChange(value);
                    }}
                  />
                  <div className="w-[66px] h-[60px] image_box">
                    <img
                      src={
                        value?.primaryPhoto
                          ? `${process.env.REACT_APP_S3_API_ENDPOINT}/${
                              value?.primaryPhoto
                            }?${Date.now()}`
                          : user
                      }
                    />
                  </div>
                  <div>
                    <p className="text-[#AAAAAA] text-[11px] font-medium">
                      Name of Vehicle
                    </p>
                    {/* <Link to=""></Link> */}
                    <p className="text-[#398CC0] font-medium cursor-pointer underline">
                      {value?.vehicleName}
                    </p>
                  </div>
                  <div>
                    <p className="text-[#AAAAAA] text-[11px] font-medium">
                      Odometer Reading
                    </p>
                    {/* <Link to=""></Link> */}
                    <p className="text-[#22212F] font-medium cursor-pointer ">
                      {value?.odometer ? value?.odometer : "-"}
                    </p>
                  </div>
                  <div>
                    <p className="text-[#AAAAAA] text-[11px] font-medium">
                      VIN Number
                    </p>
                    <p className="text-[#22212F] font-medium cursor-pointer ">
                      {value?.vin}
                    </p>
                  </div>
                  <div>
                    <p className="text-[#AAAAAA] text-[11px] font-medium">
                      Type
                    </p>
                    <p className="text-[#22212F] font-medium cursor-pointer ">
                      {value?.vehicleType.name}
                    </p>
                  </div>
                  <div>
                    <p className="text-[#AAAAAA] text-[11px] font-medium">
                      License Plate
                    </p>
                    <p className="text-[#22212F] font-medium cursor-pointer ">
                      {value.licensePlate}
                    </p>
                  </div>
                  <div>
                    <p className="text-[#AAAAAA] text-[11px] font-medium ">
                      Fuel Type
                    </p>
                    <div className="text-[#22212F] font-medium capitalize cursor-pointer flex items-center">
                      <p
                        className={`mr-[8px] rounded-[50%]  w-[8px] h-[8px] ${
                          value?.fuelType === "gasoline"
                            ? "bg-[#F25138]"
                            : value?.fuelType === "diesel"
                            ? "bg-[#00AEEF]"
                            : "bg-[#3BA936]"
                        }  `}
                      ></p>
                      {value.fuelType}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>
              {" "}
              <div className="table_card_box justify-between flex mb-[20px] p-[14px]">
                <p className="text-[#757575] text-[14px] font-medium">
                  No Data Found. You can create a Procurement Request by
                  clicking on the below button.
                </p>
              </div>
            </>
          )}
        </div>
        {/* <Table
          showHeader={false}
          className="custom_table"
          columns={columns}
          dataSource={data}
          rowSelection={rowSelection}
          pagination={false} */}
        {/* /> */}
      </div>
      {checkAddVehicle() && (
        <div className="flex items-end justify-end">
          {" "}
          <Button
            onClick={() => setPipelineDragger({ visible: true, isNew: true })}
            className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
          >
            <span>
              <PlusIcon />
            </span>
            Create Procurement Request
          </Button>
        </div>
      )}
    </>
  );
};
