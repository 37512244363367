import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { BreadCrumbs } from "../../../atoms/BreadCrumb/BreadCrumb";
import { Button } from "../../../atoms/Button/Button";
import { SaveIcon } from "../../../icons/SaveIcon";
import { useVendors } from "../context";
import * as Yup from "yup";
import { FormikText } from "../../../atoms/Text/Text";
import { CreateSelect } from "../../../atoms/Select/CretableSelect";
import { message } from "antd";
import { FormikSinglSelect } from "../../../atoms/Select/Select";
import { VendorTypeOptions } from "../VendorLoans";
import { getCurrentObjectKey } from "../../../options/VehicleLoan";
import uploadIcon from "../../../icons/designIcons/uploadIcon.svg";
import { CancelUpload, PdfImage } from "../../../icons/UploadIcons";
import { Spinner } from "../../../atoms/Spinner/spinner";
import { addIsUploadedKey, getAwsUploadUrl } from "../../../utils";
export const OneVendorPage: React.FC<{}> = (props) => {
  const { goBack, replace } = useHistory();
  const { id }: any = useParams();
  const [isEdit, _] = useState(id);
  const [attachment, setFile]: any = useState([]);
  const [spinner, setSpinner] = useState(false);
  const formRef = useRef() as any;
  const [initialValues, setInitialValues] = useState({
    name: "",
    phoneNumber: "",
    website: "",
    address: "",
    addressLineTwo: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    contactPersonName: "",
    contactPhoneNumber: "",
    contactEmail: "",
    label: [],
  });
  const {
    actions: { getOneVendor, createOne, updateOne, clearOne },
    state: { vendor },
  } = useVendors();
  useEffect(() => {
    if (id) {
      getOneVendor(id);
    }
  }, [id]);

  useEffect(() => {
    return () => clearOne();
  }, []);
  useEffect(() => {
    if (vendor && id) {
      delete vendor.id;
      delete vendor.createdAt;
      delete vendor.updatedAt;
      if (vendor.attachments?.length > 0) {
        setFile(addIsUploadedKey(vendor.attachments));
      }
      setInitialValues({
        ...vendor,
        label: vendor.label
          ? vendor.label.map((x: string) => ({ label: x, value: x }))
          : [],
        type:
          vendor.type &&
          VendorTypeOptions.map((x: any) => {
            if (x.value === vendor.type) return x;
          }),
      });
    }
  }, [vendor]);

  const submitFunction = () => {
    formRef && formRef.current.handleSubmit();
  };

  const uploadFunction = (e: any) => {
    let files = e.target.files;
    let length = attachment.length;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let item = files[i];
        let incrementedIndex = i + 1; // incrementing an variable as loop runs before updating the state.

        /*
          condition to not upload more than five files.
        */

        if (incrementedIndex + length <= 5) {
          if (
            item.type !== "image/png" &&
            item.type !== "image/jpeg" &&
            item.type !== "image/jpg" &&
            item.type !== "application/pdf" &&
            item.type !== "text/csv" &&
            item.type !==
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            message.error("Please upload only jpeg,jpg or png images");
          } else if (item.size > 5 * 1000000) {
            message.error("Couldn't upload large files");
          } else {
            let data = {
              orginal_file: item,
              name: item.name,
              url: window.URL.createObjectURL(item),
              type: item.type,
            };
            setFile((individualFiles: any) => [...individualFiles, data]);
          }
        } else return message.error("You can upload upto 5 attachments");
      }
    }
  };
  const deleteUploaded = (i: any) => {
    attachment.filter((item: any, index: any) => {
      if (index === i) {
        attachment.splice(i, 1);
      }
      setFile([...attachment]);
    });
  };
  const uploadAttachments = async () => {
    if (attachment.length > 0) {
      let attachments: any = [];
      let uploadedArray = await getAwsUploadUrl(attachment, "vendors/");
      uploadedArray.map((item: any) => {
        let obj = {
          url: item.url,
          type: item.type,
          name: item.name,
        };
        attachments.push(obj);
      });
      return attachments;
    }
  };

  return (
    <div>
      <div className="px-[24px]">
        <div className="flex justify-between items-center py-[24px]">
          <BreadCrumbs showTitle={vendor ? vendor.name : ""} />
          <div>
            <div className="flex items-center flex-row-reverse gap-4">
              <Button
                type="submit"
                onClick={() => submitFunction()}
                className="rounded-[4px] px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
              >
                <span>
                  {spinner ? (
                    <Spinner CustomClassName={"spinner_secondary"} />
                  ) : (
                    <SaveIcon />
                  )}
                </span>
                {isEdit ? "Save" : "Create"} Vendor
              </Button>
              <Button
                className="border py-3  border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
                type="button"
                onClick={goBack}
              >
                <span className="flex items-center text-[#FB6DB2]">Cancel</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="card_default rounded-[5px] mb-10 p-[24px]">
          <h2 className="text-[18px] font-[600] mb-5">Create Vendor</h2>
          <div className="">
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              enableReinitialize
              validationSchema={Yup.object({
                name: Yup.string().required("Vendor Name is required"),
                type: Yup.mixed().required("Zeeba Type is required"),
                phoneNumber: Yup.string()
                  .matches(/^[0-9]{10}$/, "Vendor Phone number is required")
                  .required("Mobile number is required"),
                website: Yup.string()
                  .matches(
                    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
                    "Enter valid url"
                  )
                  .required("Website is required"),
                address: Yup.string().required("Address is required"),
                city: Yup.string().required("City is required"),
                state: Yup.string().required("State is required"),
                zipcode: Yup.string().required("Zipcode is required"),
                country: Yup.string().required("Country is required"),
                contactPersonName: Yup.string().required(
                  "Contact Name is required"
                ),
                contactPhoneNumber: Yup.string().required(
                  "Contact Phone is required"
                ),
                contactEmail: Yup.string().required(
                  "Contact Email is required"
                ),
              })}
              onSubmit={async (values: any, actions) => {
                setSpinner(true);
                if (attachment.length > 0) {
                  values["attachments"] = await uploadAttachments();
                }
                values = {
                  ...values,
                  label: values.label.map((x: any) => x.value),
                  type: values.type.value,
                };
                if (isEdit) {
                  updateOne(id, values);
                  replace(`/vendors`);
                } else {
                  createOne(values, () => {
                    replace(`/vendors`);
                  });
                }
              }}
            >
              {({ values, setFieldValue, setErrors, resetForm }: any) => (
                <Form className="" id="my-form">
                  <div className="flex justify-between mb-[30px]">
                    <FormikText label="Name" name="name" must={true} />
                    <FormikText label="Phone" name="phoneNumber" must={true} />
                    <FormikText label="Website" name="website" must={true} />
                  </div>
                  <div className="flex justify-between mb-[30px]">
                    <FormikText label="Address" name="address" must={true} />
                    <FormikText label="Address Line 2" name="addressLineTwo" />
                    <FormikText label="City" name="city" must={true} />
                  </div>
                  <div className="flex justify-between mb-[30px]">
                    <FormikText
                      label="State/Provience/Region"
                      name="state"
                      must={true}
                    />
                    <FormikText
                      label="Zip/Postal Code"
                      name="zipcode"
                      must={true}
                    />
                    <FormikText label="Country" name="country" must={true} />
                  </div>
                  <div>
                    {" "}
                    <div className="flex justify-between mb-[30px]">
                      {" "}
                      <div className="min-w-[360px] ">
                        <FormikSinglSelect
                          must={true}
                          isClearable
                          options={VendorTypeOptions}
                          label="Vendor Type"
                          name="type"
                          value={values.type}
                          inputClassName="adjust_select_height"
                          onChange={(newValue) => {
                            setFieldValue("type", newValue);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-3/4 mb-[30px]">
                    <CreateSelect
                      label="Labels"
                      value={values.label}
                      name="label"
                      onChange={(newValue, actionMeta) => {
                        setFieldValue("label", newValue);
                      }}
                    />
                  </div>

                  <h2 className="text-[18px] font-[600] mb-5">
                    Contact Person
                  </h2>
                  <div className="flex justify-between mb-[30px]">
                    <FormikText
                      label="Contact Name"
                      name="contactPersonName"
                      must={true}
                    />
                    <FormikText
                      label="Phone Number"
                      name="contactPhoneNumber"
                      must={true}
                    />
                    <FormikText label="Email" name="contactEmail" must={true} />
                  </div>
                  <div className="mt-8">
                    <h2 className="text-[18px] font-[600] mb-5">Upload</h2>
                    <div>
                      {attachment.length < 5 && (
                        <div className="mt-8 input flex items-center bg-[#FCFCFC] pl-[35px] relative field w-full h-[100px] border rounded-[10px] border-dashed border-[#EBEBEB]">
                          <input
                            id="InputUpload"
                            type="file"
                            className="w-full h-full opacity-0 absolute cursor-pointer"
                            accept="image/*,application/pdf"
                            onChange={(e) => uploadFunction(e)}
                            multiple={true}
                          />
                          <img className="mr-5" src={uploadIcon} />
                          <div>
                            {" "}
                            <p className="text-[16px] font-medium ">
                              Click to Upload{" "}
                            </p>
                            <p className="text-[14px] text-[#757575]">
                              Maximum file size 5 MB
                            </p>
                          </div>
                        </div>
                      )}

                      <div className="mt-[24px]">
                        <div className="flex justify-between flex-wrap">
                          {attachment?.map((item: any, index: any) => (
                            <div
                              className={`border w-[49%] mb-5 rounded-[4px] bg-[#FAFAFA] ${
                                item?.type === "application/pdf" && ""
                              }  border-[#EBEBEB] px-[14px] py-[12px] flex justify-between items-center`}
                            >
                              <div className="flex  items-center">
                                <div
                                  className={`w-[32px] mr-[15px] bg-[#D9D9D9] ${
                                    item?.type === "application/pdf" &&
                                    "p-[3px]"
                                  }   h-[32px] rounded-[3px] `}
                                >
                                  <div className="w-[32px] mr-[15px] h-[32px] rounded-[3px] ">
                                    <a
                                      className=""
                                      href={
                                        !item?.is_uploaded
                                          ? item?.url
                                          : `${
                                              process.env
                                                .REACT_APP_S3_API_ENDPOINT
                                            }/${item.url}?${Date.now()}`
                                      }
                                      target="_blank"
                                    >
                                      {item?.type === "image/jpg" ||
                                      item?.type === "image/jpeg" ||
                                      item?.type === "image/png" ? (
                                        <img
                                          className="w-full h-full object-cover"
                                          src={
                                            !item?.is_uploaded
                                              ? item?.url
                                              : `${
                                                  process.env
                                                    .REACT_APP_S3_API_ENDPOINT
                                                }/${item.url}?${Date.now()}`
                                          }
                                        />
                                      ) : (
                                        <PdfImage />
                                      )}
                                    </a>
                                  </div>
                                </div>
                                <p className="font-medium text-[14px]">
                                  {item?.name}
                                </p>
                              </div>
                              <div
                                className="cursor-pointer"
                                onClick={() => deleteUploaded(index)}
                              >
                                <CancelUpload />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
