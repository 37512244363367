import react, { useEffect } from "react";
import { useState } from "react";
import user from "../../../../../icons/designIcons/user.jpg";
import { Input, Select, Drawer, message, Spin, Modal } from "antd";
import { SelectIcon } from "../../../../../icons/SelectIcon";
import {
  ActiveGridView,
  ActiveListView,
  InActiveGridView,
  InActiveListView,
} from "../../../../../icons/ViewTypes";
import { Table } from "../../../../../atoms/Table/Table";
import { MoreIcon } from "../../../../../icons/MoreIcon";
import uploadIcon from "../../../../../icons/designIcons/uploadIcon.svg";
import {
  FormikSinglSelect,
  SingleSelect,
} from "../../../../../atoms/Select/Select";
import {
  Modals,
  returnStringArray,
  UploadData,
  VehicleTypes,
} from "../../../../../options/VehicleLoan";
import { Button } from "../../../../../atoms/Button/Button";
import { useParams } from "react-router";
import { APIService } from "../../../../../services/api/request";
import { useVehicles } from "../../../context";
import {
  CancelUpload,
  ListingPdf,
  PdfImage,
} from "../../../../../icons/UploadIcons";
import { CreateSelect } from "../../../../../atoms/Select/CretableSelect";
import {
  GetuploadUrl,
  ShowDateFormat,
  UploadFiles,
} from "../../../../../utils";
import Lottie from "react-lottie-player";
import LottieData from "../../../../../LottiJson/NoData.json";
import { Search } from "../../../../../atoms/Search/Search";
import { ListTrash } from "../../../../../icons/home";
const { Option } = Select;
interface VehicleDocumentsProps {}
let uploadData: any;
const { confirm } = Modal;
export const VehicleDocuments: React.FC<VehicleDocumentsProps> = ({}) => {
  const { id }: any = useParams();
  const [view, setView] = useState("list");
  const [drawer, setdrawer] = useState(false);
  const [file, setFile]: any = useState({});
  const [location, setLocation]: any = useState([]);
  const [label, setLabel]: any = useState([]);
  const [docType, setdocType] = useState("");
  const [documents, setDocuments]: any = useState([]);
  const [selectedFile, SetSelectedFile]: any = useState({});
  const [spinner, setSpinner] = useState(false);
  const [searchText, setSearchText]: any = useState("");
  const [ClonedDocument, setClonedDocument]: any = useState([]);
  const {
    actions: {
      uploadFiles,
      addAssets,
      getOneVehicle,
      getVehicleAssests,
      deleteAssets,
    },
  } = useVehicles();
  useEffect(() => {
    getAssests();
  }, []);
  const getAssests = () => {
    getVehicleAssests(
      id,
      `?page=1&perPage=100&filter[type]=document`,
      (res) => {
        setDocuments(res.documents);
        setClonedDocument(res.documents);
      }
    );
  };
  const uploadFunction = (e: any) => {
    let file = e.target.files[0];
    SetSelectedFile(file);
    if (file.type !== "application/pdf") {
      message.error("Please upload only PDF or DOCS");
    } else if (file.size > 5 * 1000000) {
      message.error("Couldn't upload large files");
    } else {
      uploadData = UploadData(`document/${file.name}`, id, file.type);

      let data = {
        name: file.name,
        url: uploadData.key,
        type: file.type,
      };
      setFile(data);
    }
  };
  const UploadDocs = async () => {
    if (Object.keys(file).length > 0) {
      setSpinner(true);
      const res: any = await GetuploadUrl("/assets/upload/write", uploadData);
      if (res) {
        let url = res.data.signedURL;
        let uploadedData = await UploadFiles(
          url,
          selectedFile,
          selectedFile.type
        );
        if (uploadedData) {
          let data = {
            name: selectedFile.name,
            url: uploadData.key,
            type: "document", //docType
            location: returnStringArray(location),
            label: returnStringArray(label),
          };
          addAssets(`/vehicles/${id}/assets`, data, (res: any) => {
            if (res) {
              setSpinner(false);
              setdrawer(false);
              setFile({});
              setLocation([]);
              setLabel([]);
              getOneVehicle(id);
              getAssests();
            }
          });
        }
      }
    } else {
      message.error("Please upload a file");
    }
  };

  const SetMultiSelect = (value: any) => {
    setLocation(value);
  };
  const SetlabelSelect = (value: any) => {
    setLabel(value);
  };

  const cancelUpload = () => {
    setFile({});
    (document.getElementById("InputUpload") as HTMLInputElement).value = "";
  };
  const closeDragger = () => {
    cancelUpload();
    setdrawer(false);
    setLocation([]);
    setLabel([]);
  };

  const searchDocument = (value: any) => {
    if (value !== "") {
      setSearchText(value);
      let filteredArray = ClonedDocument?.filter((x: any) => {
        let name = x.name.toLowerCase();
        return name.includes(value.toLowerCase());
      });
      setDocuments(filteredArray);
    } else {
      setSearchText(value);
      setDocuments(ClonedDocument);
    }
  };
  const deleteDocs = (item: any) => {};
  return (
    <>
      <Drawer
        title="Add Document"
        placement="right"
        onClose={() => closeDragger()}
        visible={drawer}
        width="33%"
        // open={drawer}
      >
        <div className="">
          <div className="w-full mb-[20px]">
            <CreateSelect
              label="Location"
              value={location}
              name="label"
              onChange={(newValue) => {
                SetMultiSelect(newValue);
              }}
            />
          </div>
          <div className="w-full mb-[20px]">
            <CreateSelect
              label="Label"
              value={label}
              name="label"
              onChange={(newValue) => {
                SetlabelSelect(newValue);
              }}
            />
          </div>
          {Object.keys(file).length === 0 && (
            <div className="input flex items-center flex-col justify-center relative field w-full h-[300px] border-2 rounded-[10px] border-dashed border-[#9C9C9C]">
              <input
                id="InputUpload"
                type="file"
                className="w-full h-full opacity-0 absolute cursor-pointer"
                accept="application/pdf,application/msword,
              application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={(e) => uploadFunction(e)}
              />
              <img className="mb-[10px]" src={uploadIcon} />
              <p className="text-[16px] font-medium mb-[12px]">
                Click to Upload{" "}
              </p>
              <p className="text-[14px] text-[#757575]">
                Maximum file size 5 MB
              </p>
            </div>
          )}

          {Object.keys(file).length > 0 && (
            <div className="border mt-[22px] rounded-[4px]  bg-[#FAFAFA] border-[#EBEBEB] px-[14px] py-[12px] flex justify-between items-center">
              <div className="flex items-center">
                <div className="w-[32px] mr-[15px] bg-[#D9D9D9] h-[32px] rounded-[3px] p-[3px]">
                  <PdfImage />
                </div>
                <p className="font-medium text-[14px]">{file.name}</p>
              </div>
              <div className="cursor-pointer" onClick={() => cancelUpload()}>
                <CancelUpload />
              </div>
            </div>
          )}

          <div className="flex items-center justify-end mt-8">
            {spinner && <Spin />}
            <Button
              className="border ml-4 mr-4  py-[11px]   border-[#FB6DB2] px-[21px]  font-medium text-[13px] rounded-[4px]"
              type="button"
              onClick={() => closeDragger()}
            >
              <span className="flex items-center text-[#FB6DB2]">Cancel</span>
            </Button>
            <Button
              onClick={() => UploadDocs()}
              className="rounded-[4px]  px-5 py-3 bg-primary text-[13px] text-white font-semibold flex items-center gap-2 justify-center"
            >
              Upload
            </Button>{" "}
          </div>
        </div>
      </Drawer>
      <div>
        <div className="flex justify-between items-center px-[24px] py-[25px] bg-[#FAFAFA]">
          <h2 className="text-[16px] font-medium">Documents</h2>{" "}
          <button
            className="text-[#FB6DB2] font-semibold text-[15px] "
            onClick={() => setdrawer(true)}
          >
            Add Document
          </button>
        </div>
        <div className="filters_container p-[24px]  flex justify-between items-center">
          <div>
            <Search
              className="w-60"
              label=""
              placeholder={"Search by name"}
              value={searchText}
              onChange={(e) => {
                searchDocument(e.target.value);
              }}
            />
          </div>

          {/* <div>
            <i className="fas fas-search text-[13px] text-[black]"></i>
            <input
              type="text"
              className="outline-0 border mr-2 border-[#DEDEDE] px-[12px] text-[13px] text-[#7B7B7B] font-medium rounded-[4px] py-[6px]"
            />
            <Select
              suffixIcon={<SelectIcon />}
              className="select_with_input mr-2"
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ width: "400px" }}
              placeholder="Location Type"
              dropdownRender={(menu) => (
                <>
                  <Input placeholder="Please enter item" />
                  {menu}
                </>
              )}
            >
              <Option>Hari</Option>
              <Option>Hari</Option>
              <Option>Hari</Option>
            </Select>
            <Select
              suffixIcon={<SelectIcon />}
              className="select_with_input "
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ width: "400px" }}
              placeholder="Labels"
              dropdownRender={(menu) => (
                <>
                  <Input placeholder="Please enter item" />
                  {menu}
                </>
              )}
            >
              <Option>Hari</Option>
              <Option>Hari</Option>
              <Option>HariHariHariHariHariHariHariHari</Option>
            </Select>
          </div> */}
          <div className="flex  ">
            <div
              onClick={() => {
                setView("grid");
              }}
              className={`cursor-pointer w-[38px] h-[38px] rounded-[6px] flex justify-center items-center mr-2 ${
                view === "grid" ? "bg-[#FBEEF5]" : "bg-[#FBFBFB]"
              } `}
            >
              {view === "grid" ? <ActiveGridView /> : <InActiveGridView />}
            </div>
            <div
              onClick={() => {
                setView("list");
              }}
              className={`cursor-pointer w-[38px] h-[38px] rounded-[6px] flex justify-center items-center ${
                view === "list" ? "bg-[#FBEEF5]" : "bg-[#FBFBFB]"
              } `}
            >
              {view === "list" ? <ActiveListView /> : <InActiveListView />}
            </div>
          </div>
        </div>
        <div className="table_view mb-[20px] p-[24px] hide_scroll overflow-y-scroll h-[370px]">
          {documents.length > 0 ? (
            <>
              {view == "list" ? (
                <>
                  {documents?.map((item: any) => (
                    <div className="flex gap-1 justify-between items-center pb-[18px] mb-[18px] border-b border-[#F3F3F3]">
                      {" "}
                      <div className="w-[38px] h-[38px] bg-[#D9D9D9] flex items-center justify-center rounded-[5px] ">
                        <a
                          className=""
                          href={`${process.env.REACT_APP_S3_API_ENDPOINT}/${
                            item?.url
                          }?${Date.now()}`}
                          target="_blank"
                        >
                          <PdfImage />
                        </a>

                        {/* <img
                      className="object-cover w-full h-full rounded-[5px]"
                      src={user}
                    /> */}
                      </div>
                      <p className="font-medium text-[13px] w-[35%] capitalize ">
                        {item.name}
                      </p>{" "}
                      <p className="font-medium text-[13px] w-[30%]">
                        {" "}
                        {`Added on ${ShowDateFormat(item.createdAt)}`}
                      </p>{" "}
                      <div
                        style={{ background: "rgba(0, 174, 239, 0.1)" }}
                        className=" py-[8px] px-[16px] rounded-[4px] font-medium text-[#00AEEF]  text-[13px]"
                      >
                        <p className="capitalize">{item.type}</p>
                      </div>{" "}
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          confirm({
                            title: "Warning",
                            content:
                              "Are you sure want to delete this document?",
                            onOk() {
                              deleteAssets(id, item.id, () => {
                                getAssests();
                              });
                            },
                            onCancel() {},
                          });
                        }}
                      >
                        {" "}
                        <ListTrash />
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className="flex flex-wrap gap-x-[41px] flex-start  hide_scroll overflow-y-scroll h-[350px]">
                    {documents?.map((item: any) => (
                      <div className="mb-[20px] rounded-[6px] w-[190px] ">
                        <div className="w-full h-[97px] bg-[#D4D4D4] flex items-center justify-center rounded-t-[6px]">
                          <ListingPdf />
                          {/* <img
                        className="w-full h-full object-cover rounded-t-[6px]"
                        src={item.img}
                      /> */}
                        </div>
                        <div className="bg-[#FAFAFA] p-[16px] flex justify-between rounded-b-[6px]">
                          <div className="w-[149px] break-words">
                            <p className="mb-2 text-[13px] font-medium truncate">
                              {item.name}
                            </p>
                            <p className="text-[#757575] text-[13px] font-medium">
                              {ShowDateFormat(item.createdAt)}
                            </p>
                          </div>
                          <div
                            className="flex items-end cursor-pointer"
                            onClick={() => {
                              confirm({
                                title: "Warning",
                                content:
                                  "Are you sure want to delete this document?",
                                onOk() {
                                  deleteAssets(id, item.id, () => {
                                    getAssests();
                                  });
                                },
                                onCancel() {},
                              });
                            }}
                          >
                            <ListTrash />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="flex items-center h-full justify-center flex-col">
                {" "}
                <Lottie
                  loop
                  animationData={LottieData}
                  play
                  style={{ width: 150, height: 150 }}
                />
                <p
                  className="font-medium cursor-pointer text-[#FB6DB2]"
                  onClick={() => setdrawer(true)}
                >
                  Upload your Documents here
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
