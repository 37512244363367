import { useReducer } from "react";
import { container } from "./../../services/storage/context.container";
import { APIService } from "../../services/api/request";
import { useHistory, useParams } from "react-router-dom";
import { message } from "antd";
import { buildQueryString } from "../../services/utils/url";
import { destroyMessage, showMessage, UpdatedSuccessfully } from "../../utils";

const ApiRoutes = {
  base: `/services-history`,
};

export type StateType = {
  minimalDocuments: any;
  documents: any;
  groups: any;
  pagination: any;
  history: any;
  allVehicles: any;
  allVendors: any;
  allTasks: any
};

const initialState: StateType = {
  documents: [],
  groups: [],
  pagination: {},
  history: {},
  minimalDocuments: [],
  allVehicles: {},
  allVendors: {},
  allTasks: []
};

const reducer = (
  state: StateType,
  action: {
    type: string;
    payload?: any;
  }
) => {
  const { type, payload } = action;
  switch (type) {
    case "GET_MININAL_HISTORY":
      return {
        ...state,
        minimalDocuments: payload.map((x: any) => ({
          value: x.id,
          label: x.vehicleName,
        })),
      };
    case "GET_HISTORY":
      return { ...state, ...payload };
    case "GET_GROUPS":
      return { ...state, groups: [...payload] };
    case "GET_ONE_HISTORY":
      return { ...state, history: { ...payload } };
    case "GET_VEHICLES":
      return { ...state, allVehicles: { ...payload } };
    case "GET_VENDORS":
      return { ...state, allVendors: { ...payload } };
    case "GET_SERVICE_TASKS":
      return { ...state, allTasks: payload.map((x: any) => ({ ...x, label: x.name, value: x.id })) }
    default:
      return state;
  }
};

export const { useContext: useServiceHistory, Provider: HistoryProvider } =
  container(() => {
    const [state, dispatch] = useReducer(reducer, {
      ...initialState,
    });
    let history = useHistory();
    const getAllHistory = (
      mininal?: boolean,
      params?: any,
      callback?: (data: any) => void
    ) => {
      if (!mininal) {
      }

      params = buildQueryString(params);
      showMessage();
      APIService.get(
        mininal
          ? `${ApiRoutes.base}?all=true`
          : `${ApiRoutes.base}${params ? `${params}` : ""}`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          const response: any = res.data;
          if (mininal) {
            dispatch({ type: "GET_MININAL_VEHICLES", payload: response });
          } else {
            if (typeof callback === "function") {
              callback(response);
            }
            dispatch({ type: "GET_HISTORY", payload: response });
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const updateOne = (
      id: string,
      data: any,
      callback?: (data: any) => void
    ) => {
      showMessage();
      APIService.put(
        `${ApiRoutes.base}/${id}`,
        data,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          UpdatedSuccessfully();
          // dispatch({ type: "GET_HISTORY", payload: res });

          if (typeof callback === "function") {
            callback(res);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };

    const createOne = (data: any, callback?: (data: any) => void) => {
      APIService.post(
        `${ApiRoutes.base}`,
        data,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          message.success("Created Successfully!!!");
          callback && callback(res);
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          } else {
          }
        });
    };
    const getAllVehicles = (callback?: (data: any) => void) => {
      APIService.get(
        "/vehicles?all=true",
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          dispatch({ type: "GET_VEHICLES", payload: res });

          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          } else {
          }
        });
    };
    const getAllVendors = (callback?: (data: any) => void) => {
      APIService.get(
        "/vendors?all=true",
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          dispatch({ type: "GET_VENDORS", payload: res });
          if (typeof callback === "function") {
            callback({});
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          } else {
            message.error("Vehicle Creation Failed");
          }
        });
    };

    const getOneHistory = (id: string, callback?: (data: any) => void) => {
      showMessage();
      APIService.get(
        `${ApiRoutes.base}/${id}`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((res: any) => {
          destroyMessage("loading");
          const response: any = res.data;
          dispatch({ type: "GET_ONE_HISTORY", payload: response });
          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error: any) => {
          if (typeof error === "object" && error.status) {
            message.error(error.message);
          }
        });
    };
    const getAllGroups = (callback?: (response: any) => void) => {
      APIService.get(
        `/groups?all=true`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((response: any) => {
          dispatch({ type: "GET_GROUPS", payload: response.data });
          callback && callback(response.data);
        })
        .catch((err: any) => { });
    };

    const getAllServiceTasks = (callback?: (response: any) => void) => {
      APIService.get(
        `/services-history/tasks?all=true`,
        undefined,
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((response: any) => {
          dispatch({ type: "GET_SERVICE_TASKS", payload: response.data });
          callback && callback(response.data);
        })
        .catch((err: any) => { });
    };
    const DeleteHistory = (id: string, callback?: (response: any) => void) => {
      APIService.delete(
        `${ApiRoutes.base}/${id}`,
        {},
        process.env.REACT_APP_API_ENDPOINT
      )
        .then((response: any) => {
          callback && callback(response);
        })
        .catch((err: any) => { });
    };

    return {
      state,
      actions: {
        getAllHistory,
        getOneHistory,
        updateOne,
        createOne,
        DeleteHistory,
        getAllVendors,
        getAllVehicles,
        getAllServiceTasks
      },
    };
  });
